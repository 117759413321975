import React, { useEffect, useRef, useState } from "react";
import "./app.css"
export default function FlashcardImage({ flashcard, question }) {
  const [flip, setFlip] = useState(false);
  const [height, setHeight] = useState("initial")

  const frontEl = useRef()
  const backEl = useRef()

  function setMaxHeight(){
    const frontHeight = frontEl.current.getBoundingClientRect().height + 20
    const backHeight = backEl.current.getBoundingClientRect().height + 20
    setHeight(Math.max(frontHeight, backHeight, 150))

  }

  useEffect(setMaxHeight, [flashcard,question, flashcard.answer]);
  useEffect(() => {
    window.addEventListener("resize", setMaxHeight)
    return () => window.removeEventListener("resize", setMaxHeight)
  })

  return (
    <div
      className={`card ${flip ? "flip" : ""}`}
      style={{ height }}
      onClick={() => setFlip(!flip)}>

      <div className={"front"} ref={frontEl}>
        <h2>{flip ? "" : flashcard.question}</h2>
        <div className={"crop"}>
          <img src={flashcard.image} alt={"Weird Muscle"}/>
        </div>
      </div>
      <div className={"back"} ref={backEl}>
        {flashcard.answer}
      </div>
    </div>
  );
}