export const defaultQuestions = [
  {
    "id": "insertion-Arm-arm-medial_head_of_triceps_brachii",
    "question": "What is the insertion of the Medial Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Olecranon of ulna",
    "options": [
      "Radial tuberosity",
      "Lateral aspect of styloid process of radius",
      "Ulnar tuberosity and coronoid process"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-latissimus_dorsi",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-LatissimusDorsi-5.png",
    "answer": "Latissimus Dorsi"
  },
  {
    "id": "insertion-Shoulder-shoulder-supraspinatus",
    "question": "What is the insertion of the Supraspinatus?",
    "group": "Shoulder",
    "answer": "Greater tubercle of humerus",
    "options": [
      "Coracoid process of scapula",
      "Deltoid tuberosity of humerus",
      "Medial lip of intertubercular sulcus of humerus"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-latissimus_dorsi",
    "question": "What is the origin of the Latissimus Dorsi?",
    "group": "Shoulder",
    "answer": "Spinous processes of inferior thoracic and all lumbar and sacral vertebrae| ribs 8-12| and thoracolumbar fascia",
    "options": [
      "Cartilages of ribs 2-6| body of sternum| and inferior| medial portion of clavicle",
      "Ligamentum nuchae and the spinous processes of T2-T5",
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-pectoralis_minor",
    "question": "What is the insertion of the Pectoralis Minor?",
    "group": "Shoulder",
    "answer": "Coracoid process of scapula",
    "options": [
      "Crest of greater tubercle and lateral lip of intertubercular sulcus of humerus ",
      "Vertebral border of scapula",
      "Clavicle and scapula"
    ]
  },
  {
    "id": "origin-Leg-leg-fibularis_longus",
    "question": "What is the origin of the Fibularis Longus?",
    "group": "Leg",
    "answer": "Head and proximal shaft of fibula",
    "options": [
      "Anterior surface of fibula",
      "Posterior surface of fibula",
      "Mid Lateral margin of fibula"
    ]
  },
  {
    "id": "identify-Hip-hip-obturator_externus",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-ObturatorExternus-5.png",
    "answer": "Obturator Externus"
  },
  {
    "id": "origin-Hip-hip-tensor_fasciae_latae",
    "question": "What is the origin of the Tensor Fasciae Latae?",
    "group": "Hip",
    "answer": "Iliac crest and lateral surface of anterior superior iliac spine",
    "options": [
      "Lateral surface of ilium between inferior and anterior gluteal lines",
      "Ischial tuberosity ",
      "Ischial tuberosity"
    ]
  },
  {
    "id": "origin-Hip-hip-obturator_internus",
    "question": "What is the origin of the Obturator Internus?",
    "group": "Hip",
    "answer": "Obturator foramen",
    "options": [
      "Iliac crest and lateral surface of anterior superior iliac spine",
      "Anterior obturator foramen",
      "Lateral surface of ilium between inferior and anterior gluteal lines"
    ]
  },
  {
    "id": "insertion-Leg-leg-flexor_digitorum_longus",
    "question": "What is the insertion of the Flexor Digitorum Longus?",
    "group": "Leg",
    "answer": "Inferior surface of distal phalanges, toes 2-5",
    "options": [
      "Posterior portion of calcaneus",
      "Dorsal surface of base of metatarsal bone 5",
      "Calcaneus via calcaneal tendon"
    ]
  },
  {
    "id": "identify-Leg-leg-medial_head_of_gastrocnemius",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-MedialGastrocnemius-5.png",
    "answer": "Medial Head of Gastrocnemius"
  },
  {
    "id": "insertion-Hip-hip-obturator_internus",
    "question": "What is the insertion of the Obturator Internus?",
    "group": "Hip",
    "answer": "Intertrochanteric crest of femur",
    "options": [
      "Iliotibial tract, gluteal tuberosity",
      "Greater trochanter of femur",
      "Iliotibial tract"
    ]
  },
  {
    "id": "identify-Hip-hip-piriformis",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-Piriformis-5.png",
    "answer": "Piriformis"
  },
  {
    "id": "identify-Leg-leg-lateral_head_of_gastrocnemius",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-LateralGastrocnemius-5.png",
    "answer": "Lateral Head of Gastrocnemius"
  },
  {
    "id": "identify-Leg-leg-flexor_digitorum_longus",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-FlexorDigitorumLongus-5.png",
    "answer": "Flexor Digitorum Longus"
  },
  {
    "id": "action-Leg-leg-soleus",
    "question": "What is the action of the Soleus?",
    "group": "Leg",
    "answer": "Plantar flexion at ankle",
    "options": [
      "Plantar flexion at ankle, flexion at knee",
      "Flexion at joints of toes 2-5, plantarflexion",
      "Flexion at joints of great toe, plantar flexion"
    ]
  },
  {
    "id": "origin-Leg-leg-lateral_head_of_gastrocnemius",
    "question": "What is the origin of the Lateral Head of Gastrocnemius?",
    "group": "Leg",
    "answer": "Lateral condyle of femur",
    "options": [
      "Medial condyle of femur",
      "Medial surface of the fibula",
      "Mid Lateral margin of fibula"
    ]
  },
  {
    "id": "insertion-Hip-hip-superior_gemellus",
    "question": "What is the insertion of the Superior Gemellus?",
    "group": "Hip",
    "answer": "Intertrochanteric crest of femur",
    "options": [
      "Iliotibial tract",
      "Iliotibial tract, gluteal tuberosity",
      "Greater trochanter of femur"
    ]
  },
  {
    "id": "origin-Hip-hip-gluteus_minimus",
    "question": "What is the origin of the Gluteus Minimus?",
    "group": "Hip",
    "answer": "Lateral surface of ilium between inferior and anterior gluteal lines",
    "options": [
      "Anterior obturator foramen",
      "Iliac crest| posterior gluteal line| lateral surface of ilium",
      "Anterior iliac crest"
    ]
  },
  {
    "id": "action-Forearm-forearm-flexor_digitorum_superficialis",
    "question": "What is the action of the Flexor Digitorum Superficialis?",
    "group": "Forearm",
    "answer": "Flexion at proximal interphalangeal, metacarpophalangeal, and wrist joints",
    "options": [
      "Flexion at joints at thumb",
      "Supinates forearm and hand",
      "Extension at joints of thumb, abduction at wrist"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-deltoid",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-Deltoid-9.png",
    "answer": "Deltoid"
  },
  {
    "id": "identify-Shoulder-shoulder-subclavius",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-Subclavius-13.png",
    "answer": "Subclavius"
  },
  {
    "id": "origin-Hip-hip-obturator_externus",
    "question": "What is the origin of the Obturator Externus?",
    "group": "Hip",
    "answer": "Anterior obturator foramen",
    "options": [
      "Obturator foramen",
      "Iliac crest and lateral surface of anterior superior iliac spine",
      "Lateral surface of ilium between inferior and anterior gluteal lines"
    ]
  },
  {
    "id": "origin-Arm-arm-lateral_head_of_triceps_brachii",
    "question": "What is the origin of the Lateral Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Superior| lateral margin of humerus",
    "options": [
      "Coracoid process",
      "Ridge superior to lateral epicondyle of humerus",
      "Supraglenoid tubercle"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-teres_minor",
    "question": "What is the origin of the Teres Minor?",
    "group": "Shoulder",
    "answer": "Lateral border of scapula",
    "options": [
      "Clavicle and scapula",
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12",
      "Supraspinous fossa of scapula"
    ]
  },
  {
    "id": "action-Thigh-thigh-adductor_brevis",
    "question": "What is the action of the Adductor Brevis?",
    "group": "Thigh",
    "answer": "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
    "options": [
      "Lesser trochanter",
      "Extension at knee",
      "Flexion at knee; abduction, flexion, and lateral rotation at hip"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-infraspinatus",
    "question": "What is the insertion of the Infraspinatus?",
    "group": "Shoulder",
    "answer": "Greater tubercle of humerus",
    "options": [
      "Medial lip of intertubercular sulcus of humerus",
      "Lesser tubercle of humerus",
      "Anterior surface of vertebral border of scapula"
    ]
  },
  {
    "id": "action-Arm-arm-long_head_of_triceps_brachii",
    "question": "What is the action of the Long Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Extension at elbow, extension and adduction at shoulder",
    "options": [
      "Flexion at elbow",
      "Flexion at elbow and shoulder, supination of forearm and hand",
      "Extension at elbow"
    ]
  },
  {
    "id": "action-Hand-hand-palmaris_brevis",
    "question": "What is the action of the Palmaris Brevis?",
    "group": "Hand",
    "answer": "Moves skin on medial border toward midline of palm",
    "options": [
      "Flexion at metacarpophalangeal joint",
      "Abduction at metacarpophalangeal joints of digits 2-4, flexion at metacarpophalangeal joints; extension at interphalangeal joints",
      "Abduction of thumb"
    ]
  },
  {
    "id": "identify-Hip-hip-gluteus_medius",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-GluteusMedius-5.png",
    "answer": "Gluteus Medius"
  },
  {
    "id": "origin-Thigh-thigh-sartorius",
    "question": "What is the origin of the Sartorius?",
    "group": "Thigh",
    "answer": "Anterior superior iliac spine",
    "options": [
      "Superior 2/3 of anterior and lateral surfaces of femur",
      "Inferior ramus of pubis",
      "Linea aspera"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-teres_major",
    "question": "What is the insertion of the Teres Major?",
    "group": "Shoulder",
    "answer": "Medial lip of intertubercular sulcus of humerus",
    "options": [
      "Vertebral border of scapula",
      "Deltoid tuberosity of humerus",
      "Greater tubercle of humerus"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-levator_scapulae",
    "question": "What is the origin of the Levator Scapulae?",
    "group": "Shoulder",
    "answer": "Transverse process of C1-C4",
    "options": [
      "Ligamentum nuchae and the spinous processes of T2-T5",
      "Inferior angle of scapula",
      "Subscapular fossa of scapula"
    ]
  },
  {
    "id": "identify-Thigh-thigh-long_head_of_biceps_femoris",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-LongBicepsFemoris-5.png",
    "answer": "Long Head of Biceps Femoris"
  },
  {
    "id": "insertion-Thigh-thigh-rectus_femoris",
    "question": "What is the insertion of the Rectus Femoris?",
    "group": "Thigh",
    "answer": "Tibial tuberosity",
    "options": [
      "Medial surface of tibial shaft",
      "Proximal, medial surface of tibia",
      "Medial surface of tibial shaft near tibial tuberosity"
    ]
  },
  {
    "id": "action-Forearm-forearm-pronator_quadratus",
    "question": "What is the action of the Pronator Quadratus?",
    "group": "Forearm",
    "answer": "Pronates forearm and hand",
    "options": [
      "Supinates forearm and hand",
      "Extension at elbow",
      "Flexion at distal interphalangeal joints"
    ]
  },
  {
    "id": "identify-Hip-hip-superior_gemellus",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-GemellusSuperior-5.png",
    "answer": "Superior Gemellus"
  },
  {
    "id": "action-Thigh-thigh-vastus_intermedius",
    "question": "What is the action of the Vastus Intermedius?",
    "group": "Thigh",
    "answer": "Extension at knee",
    "options": [
      "Flexion at knee",
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Lesser trochanter"
    ]
  },
  {
    "id": "insertion-Hip-hip-piriformis",
    "question": "What is the insertion of the Piriformis?",
    "group": "Hip",
    "answer": "Greater trochanter of femur",
    "options": [
      "Intertrochanteric crest of femur",
      "Iliotibial tract",
      "Iliotibial tract, gluteal tuberosity"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-supraspinatus",
    "question": "What is the origin of the Supraspinatus?",
    "group": "Shoulder",
    "answer": "Supraspinous fossa of scapula",
    "options": [
      "Transverse process of C1-C4",
      "Anterior and superior margins or ribs 1-8 or 1-9",
      "Ligamentum nuchae and the spinous processes of T2-T5"
    ]
  },
  {
    "id": "insertion-Leg-leg-medial_head_of_gastrocnemius",
    "question": "What is the insertion of the Medial Head of Gastrocnemius?",
    "group": "Leg",
    "answer": "Calcaneus via calcaneal tendon",
    "options": [
      "Navicular,  all three cuneiforms, cuboid, second, third, and fourth metatarsal bones",
      "Posterior portion of calcaneus",
      "Superior surface of phalanges, distal phalanx of great toe"
    ]
  },
  {
    "id": "action-Thigh-thigh-short_head_of_biceps_femoris",
    "question": "What is the action of the Short Head of Biceps Femoris?",
    "group": "Thigh",
    "answer": "Flexion at knee",
    "options": [
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
      "Flexion at hip",
      "Extension at knee"
    ]
  },
  {
    "id": "insertion-Axial-axial-intertransversarii",
    "question": "What is the insertion of the Intertransversarii?",
    "group": "Axial",
    "answer": "Transverse process",
    "options": [
      "12th rib, transverse processes of L1-L5",
      "Inferior border of superior rib",
      "Central tendon sheet"
    ]
  },
  {
    "id": "origin-Thigh-thigh-short_head_of_biceps_femoris",
    "question": "What is the origin of the Short Head of Biceps Femoris?",
    "group": "Thigh",
    "answer": "Linea aspera",
    "options": [
      "Superior ramus of pubis",
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
      "Anterior inferior iliac spine"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-extensor_indicis",
    "question": "What is the insertion of the Extensor Indicis?",
    "group": "Forearm",
    "answer": "Posterior surface of proximal phalanx of index finger",
    "options": [
      "Pisiform, hamate, and base of fifth metacarpal bones",
      "Lateral margin of first metacarpal bone and trapezium",
      "Base of proximal phalanx of thumb"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-flexor_digitorum_superficialis",
    "question": "What is the insertion of the Flexor Digitorum Superficialis?",
    "group": "Forearm",
    "answer": "Base of middle phalanges of digits 2-5",
    "options": [
      "Base of distal phalanges of digits 2-5",
      "Base of proximal phalanx of thumb",
      "Base of fifth metacarpal bone"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-infraspinatus",
    "question": "What is the origin of the Infraspinatus?",
    "group": "Shoulder",
    "answer": "Infraspinous fossa of scapula",
    "options": [
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12",
      "Spinous processes of inferior thoracic and all lumbar and sacral vertebrae| ribs 8-12| and thoracolumbar fascia",
      "Clavicle and scapula"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-sartorius",
    "question": "What is the insertion of the Sartorius?",
    "group": "Thigh",
    "answer": "Medial surface of tibial shaft near tibial tuberosity",
    "options": [
      "Posterior surface of medial condyle of tibia",
      "Pectineal line",
      "Lateral condyle of tibia"
    ]
  },
  {
    "id": "identify-Thigh-thigh-vastus_intermedius",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-VastusIntermedius-13.png",
    "answer": "Vastus Intermedius"
  },
  {
    "id": "origin-Shoulder-shoulder-subclavius",
    "question": "What is the origin of the Subclavius?",
    "group": "Shoulder",
    "answer": "First rib",
    "options": [
      "Clavicle and scapula",
      "Infraspinous fossa of scapula",
      "Cartilages of ribs 2-6| body of sternum| and inferior| medial portion of clavicle"
    ]
  },
  {
    "id": "action-Hip-hip-superior_gemellus",
    "question": "What is the action of the Superior Gemellus?",
    "group": "Hip",
    "answer": "Lateral rotation and abduction of hip",
    "options": [
      "Extension of the knee, lateral rotation of the leg",
      "Lateral rotation of hip",
      "Abduction and medial rotation at hip"
    ]
  },
  {
    "id": "action-Axial-axial-spinalis_thoracis",
    "question": "What is the action of the Spinalis Thoracis?",
    "group": "Axial",
    "answer": "Bilateral: extension",
    "options": [
      "Compress abdominal cavity",
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
      "Elevate ribs; Bilateral: flexes the neck; Unilateral: Lateral flexion & ipsilateral rotation"
    ]
  },
  {
    "id": "action-Hip-hip-piriformis",
    "question": "What is the action of the Piriformis?",
    "group": "Hip",
    "answer": "Lateral rotation and abduction of hip",
    "options": [
      "Major extensor of hip joint, assists in laterally rotating the thigh",
      "Abduction and medial rotation at hip",
      "Extension of the knee, lateral rotation of the leg"
    ]
  },
  {
    "id": "action-Axial-axial-internal_oblique",
    "question": "What is the action of the Internal Oblique?",
    "group": "Axial",
    "answer": "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation",
    "options": [
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
      "Depresses 12th rib, lateral flexion",
      "Flexes, compresses abdominal cavity"
    ]
  },
  {
    "id": "origin-Axial-axial-internal_intercostals",
    "question": "What is the origin of the Internal Intercostals?",
    "group": "Axial",
    "answer": "Superior border of rib",
    "options": [
      "Thoracolumbar fascia| iliac crest",
      "Transverse & costal processes of C2-C6",
      "Sternal end of clavicle| manubrium"
    ]
  },
  {
    "id": "action-Foot-foot-flexor_hallucis_brevis",
    "question": "What is the action of the Flexor Hallucis Brevis?",
    "group": "Foot",
    "answer": "Flexion at metatarsophalangeal joint of great toe",
    "options": [
      "Adduction and flexion at metatarsophalangeal joint of great toe",
      "Adduction of metatarsophalangeal joints of toes 3-5; flexion of metatarsophalangeal joints and extension at interphalangeal joints",
      "Flexion at metatarsophalangeal joints; extension at interphalangeal joints of toes 2-5"
    ]
  },
  {
    "id": "identify-Thigh-thigh-adductor_brevis",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-AdductorBrevis-13.png",
    "answer": "Adductor Brevis"
  },
  {
    "id": "origin-Thigh-thigh-psoas_major",
    "question": "What is the origin of the Psoas Major?",
    "group": "Thigh",
    "answer": "Anterior surfaces and lower borders of transverse processes of L1 - L5 and bodies and discs of T12 - L5",
    "options": [
      "Inferior ramus of pubis",
      "Iliac fossa",
      "Linea aspera"
    ]
  },
  {
    "id": "identify-Hip-hip-gluteus_minimus",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-GluteusMinimus-5.png",
    "answer": "Gluteus Minimus"
  },
  {
    "id": "action-Arm-arm-medial_head_of_triceps_brachii",
    "question": "What is the action of the Medial Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Extension at elbow",
    "options": [
      "Extension at elbow, extension and adduction at shoulder",
      "Flexion at elbow",
      "Flexion at elbow and shoulder, supination of forearm and hand"
    ]
  },
  {
    "id": "identify-Thigh-thigh-iliacus",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-Iliacus-13.png",
    "answer": "Iliacus"
  },
  {
    "id": "identify-Thigh-thigh-semimembranosus",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-Semimembranosus-5.png",
    "answer": "Semimembranosus"
  },
  {
    "id": "origin-Thigh-thigh-gracilis",
    "question": "What is the origin of the Gracilis?",
    "group": "Thigh",
    "answer": "Inferior ramus of pubis",
    "options": [
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
      "Linea aspera",
      "Ischial tuberosity"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-semimembranosus",
    "question": "What is the insertion of the Semimembranosus?",
    "group": "Thigh",
    "answer": "Posterior surface of medial condyle of tibia",
    "options": [
      "Linea aspera and medial tubercle of femur",
      "Proximal, medial surface of tibia",
      "Medial surface of tibial shaft"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-pectoralis_major",
    "question": "What is the action of the Pectoralis Major?",
    "group": "Shoulder",
    "answer": "Flexion, adduction, and medial rotation at shoulder",
    "options": [
      "Elevates scapula",
      "Lateral rotation and adduction at shoulder",
      "Lateral rotation at shoulder"
    ]
  },
  {
    "id": "identify-Leg-leg-plantaris",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-Plantaris-5.png",
    "answer": "Plantaris"
  },
  {
    "id": "identify-Shoulder-shoulder-subscapularis",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-Subscapularis-13.png",
    "answer": "Subscapularis"
  },
  {
    "id": "insertion-Axial-axial-transverse_abdominis",
    "question": "What is the insertion of the Transverse Abdominis?",
    "group": "Axial",
    "answer": "Linea alba",
    "options": [
      "Mastoid process",
      "12th rib, transverse processes of L1-L5",
      "Inferior borders of ribs 8-12, linea alba"
    ]
  },
  {
    "id": "action-Foot-foot-dorsal_interossei_(4)",
    "question": "What is the action of the Dorsal Interossei (4)?",
    "group": "Foot",
    "answer": "Abduction at metatarsophalangeal joints of toes 3 and 4; flexion of metatarsophalangeal joints and extension at the interphalangeal joints of toes 2-4",
    "options": [
      "Extension at metatarsophalangeal joints of toes 1-4",
      "Abduction at metatarsophalangeal joints of great toe",
      "Flexion at metatarsophalangeal joint of great toe"
    ]
  },
  {
    "id": "action-Thigh-thigh-psoas_major",
    "question": "What is the action of the Psoas Major?",
    "group": "Thigh",
    "answer": "Lesser trochanter",
    "options": [
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
      "Extension at knee"
    ]
  },
  {
    "id": "origin-Forearm-forearm-flexor_digitorum_superficialis",
    "question": "What is the origin of the Flexor Digitorum Superficialis?",
    "group": "Forearm",
    "answer": "Medial epicondyle of humerus",
    "options": [
      "Anterior shaft of radius; interosseous membrane",
      "Proximal dorsal surfaces of ulna and radius",
      "Shaft of radius; interosseous membrane"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-levator_scapulae",
    "question": "What is the insertion of the Levator Scapulae?",
    "group": "Shoulder",
    "answer": "Vertebral border of scapula",
    "options": [
      "Deltoid tuberosity of humerus",
      "Crest of greater tubercle and lateral lip of intertubercular sulcus of humerus ",
      "Clavicle and scapula"
    ]
  },
  {
    "id": "identify-Thigh-thigh-short_head_of_biceps_femoris",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-ShortBicepsFemoris-5.png",
    "answer": "Short Head of Biceps Femoris"
  },
  {
    "id": "insertion-Axial-axial-interspinales",
    "question": "What is the insertion of the Interspinales?",
    "group": "Axial",
    "answer": "Spinous process",
    "options": [
      "Mastoid process",
      "Costal cartilages of ribs 5-7, xiphoid process",
      "Transverse process"
    ]
  },
  {
    "id": "action-Arm-arm-brachialis",
    "question": "What is the action of the Brachialis?",
    "group": "Arm",
    "answer": "Flexion at elbow",
    "options": [
      "Flexion at elbow and shoulder, supination of forearm and hand",
      "Extension at elbow",
      "Extension at elbow, extension and adduction at shoulder"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-rhomboid_major",
    "question": "What is the insertion of the Rhomboid Major?",
    "group": "Shoulder",
    "answer": "Vertebral border of scapula",
    "options": [
      "Greater tubercle of humerus",
      "Coracoid process of scapula",
      "Deltoid tuberosity of humerus"
    ]
  },
  {
    "id": "origin-Forearm-forearm-extensor_pollicis_brevis",
    "question": "What is the origin of the Extensor Pollicis Brevis?",
    "group": "Forearm",
    "answer": "Shaft of radius; interosseous membrane",
    "options": [
      "Medial epicondyle of humerus and coronoid process of ulna",
      "Medial and posterior surfaces of ulnar| medial surfaces of coronoid process; interosseous membrane",
      "Posterior surface of lateral epicondyle of humerus"
    ]
  },
  {
    "id": "origin-Leg-leg-soleus",
    "question": "What is the origin of the Soleus?",
    "group": "Leg",
    "answer": "Soleal line",
    "options": [
      "Medial surface of the fibula",
      "Mid Lateral margin of fibula",
      "Lateral condyle of femur"
    ]
  },
  {
    "id": "origin-Arm-arm-long_head_of_biceps_brachii",
    "question": "What is the origin of the Long Head of Biceps Brachii?",
    "group": "Arm",
    "answer": "Supraglenoid tubercle",
    "options": [
      "Coracoid process",
      "Infraglenoid tubercle of scapula",
      "Ridge superior to lateral epicondyle of humerus"
    ]
  },
  {
    "id": "action-Arm-arm-coracobrachialis",
    "question": "What is the action of the Coracobrachialis?",
    "group": "Arm",
    "answer": "Flexion at elbow",
    "options": [
      "Extension at elbow, extension and adduction at shoulder",
      "Extension at elbow",
      "Flexion at elbow and shoulder, supination of forearm and hand"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-vastus_intermedius",
    "question": "What is the insertion of the Vastus Intermedius?",
    "group": "Thigh",
    "answer": "Tibial tuberosity",
    "options": [
      "Linea aspera",
      "Linea aspera and medial tubercle of femur",
      "Proximal, medial surface of tibia"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-subclavius",
    "question": "What is the insertion of the Subclavius?",
    "group": "Shoulder",
    "answer": "Clavicle and scapula",
    "options": [
      "Anterior surface of vertebral border of scapula",
      "Lesser tubercle of humerus",
      "Greater tubercle of humerus"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_carpi_radialis_longus",
    "question": "What is the action of the Extensor Carpi Radialis Longus?",
    "group": "Forearm",
    "answer": "Extension and abduction at wrist",
    "options": [
      "Flexion at proximal interphalangeal, metacarpophalangeal, and wrist joints",
      "Extension at joints of little finger, extension at wrist",
      "Flexion at wrist"
    ]
  },
  {
    "id": "insertion-Hip-hip-gluteus_minimus",
    "question": "What is the insertion of the Gluteus Minimus?",
    "group": "Hip",
    "answer": "Greater trochanter of femur",
    "options": [
      "Iliotibial tract",
      "Intertrochanteric crest of femur",
      "Iliotibial tract, gluteal tuberosity"
    ]
  },
  {
    "id": "action-Leg-leg-tibialis_posterior",
    "question": "What is the action of the Tibialis Posterior?",
    "group": "Leg",
    "answer": "Inversion of foot, plantar flexion at ankle",
    "options": [
      "Dorsiflexion at ankle, eversion of foot",
      "Extension of toes 2-5, dorsiflexion at ankle",
      "Flexion at joints of toes 2-5, plantarflexion"
    ]
  },
  {
    "id": "origin-Forearm-forearm-supinator",
    "question": "What is the origin of the Supinator?",
    "group": "Forearm",
    "answer": "Lateral epicondyle of humerus and ridge near radial notch of ulna",
    "options": [
      "Anterior and medial surfaces of distal ulna",
      "Medial epicondyle of humerus",
      "Lateral epicondyle of humerus"
    ]
  },
  {
    "id": "origin-Forearm-forearm-extensor_carpi_radialis_brevis",
    "question": "What is the origin of the Extensor Carpi Radialis Brevis?",
    "group": "Forearm",
    "answer": "Lateral epicondyle of humerus",
    "options": [
      "Proximal dorsal surfaces of ulna and radius",
      "Anterior and medial surfaces of distal ulna",
      "Posterior and lateral surfaces of ulna; interosseous membrane"
    ]
  },
  {
    "id": "action-Axial-axial-interspinales",
    "question": "What is the action of the Interspinales?",
    "group": "Axial",
    "answer": "Extension of cervical and lumbar spine",
    "options": [
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
      "Bilateral: extends; Unilateral: contralateral rotation",
      "Elevate ribs; Bilateral: flexes the neck; Unilateral: Lateral flexion & ipsilateral rotation"
    ]
  },
  {
    "id": "action-Leg-leg-fibularis_longus",
    "question": "What is the action of the Fibularis Longus?",
    "group": "Leg",
    "answer": "Eversion of foot and plantar flexion at ankle",
    "options": [
      "Flexion at joints of great toe, plantar flexion",
      "Plantar flexion at ankle, flexion at knee",
      "Extension at joints of great toe, dorsiflexion at ankle"
    ]
  },
  {
    "id": "identify-Thigh-thigh-rectus_femoris",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-RectusFemoris-13.png",
    "answer": "Rectus Femoris"
  },
  {
    "id": "origin-Forearm-forearm-anconeus",
    "question": "What is the origin of the Anconeus?",
    "group": "Forearm",
    "answer": "Posterior surface of lateral epicondyle of humerus",
    "options": [
      "Lateral supracondylar ridge of humerus",
      "Posterior surface of ulna; interosseous membrane",
      "Medial epicondyle of humerus"
    ]
  },
  {
    "id": "origin-Forearm-forearm-flexor_pollicis_longus",
    "question": "What is the origin of the Flexor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Anterior shaft of radius; interosseous membrane",
    "options": [
      "Medial epicondyle of humerus and coronoid process of ulna",
      "Medial epicondyle of humerus",
      "Posterior surface of lateral epicondyle of humerus"
    ]
  },
  {
    "id": "insertion-Hip-hip-tensor_fasciae_latae",
    "question": "What is the insertion of the Tensor Fasciae Latae?",
    "group": "Hip",
    "answer": "Iliotibial tract",
    "options": [
      "Intertrochanteric crest of femur",
      "Greater trochanter of femur",
      "Iliotibial tract, gluteal tuberosity"
    ]
  },
  {
    "id": "origin-Axial-axial-external_oblique",
    "question": "What is the origin of the External Oblique?",
    "group": "Axial",
    "answer": "External & inferior borders of ribs 5-12",
    "options": [
      "Spinous process",
      "Sternal end of clavicle| manubrium",
      "Sacrum & transverse processes"
    ]
  },
  {
    "id": "action-Axial-axial-transverse_abdominis",
    "question": "What is the action of the Transverse Abdominis?",
    "group": "Axial",
    "answer": "Compress abdominal cavity",
    "options": [
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation",
      "Bilateral: extends; Unilateral: contralateral rotation",
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation"
    ]
  },
  {
    "id": "action-Axial-axial-internal_intercostals",
    "question": "What is the action of the Internal Intercostals?",
    "group": "Axial",
    "answer": "Depresses ribs",
    "options": [
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation",
      "Depresses 12th rib, lateral flexion",
      "Bilateral: extension; Unilateral: lateral flexion"
    ]
  },
  {
    "id": "origin-Thigh-thigh-vastus_lateralis",
    "question": "What is the origin of the Vastus Lateralis?",
    "group": "Thigh",
    "answer": "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
    "options": [
      "Ischial tuberosity",
      "Superior 2/3 of anterior and lateral surfaces of femur",
      "Linea aspera"
    ]
  },
  {
    "id": "insertion-Leg-leg-lateral_head_of_gastrocnemius",
    "question": "What is the insertion of the Lateral Head of Gastrocnemius?",
    "group": "Leg",
    "answer": "Calcaneus via calcaneal tendon",
    "options": [
      "Navicular,  all three cuneiforms, cuboid, second, third, and fourth metatarsal bones",
      "Superior surface of phalanges, distal phalanx of great toe",
      "Dorsal surface of base of metatarsal bone 5"
    ]
  },
  {
    "id": "origin-Arm-arm-brachialis",
    "question": "What is the origin of the Brachialis?",
    "group": "Arm",
    "answer": "Distal half of the anterior surface of humerus",
    "options": [
      "Superior| lateral margin of humerus",
      "Posterior surface of humerus| inferior to radial groove",
      "Infraglenoid tubercle of scapula"
    ]
  },
  {
    "id": "origin-Thigh-thigh-semitendinosus",
    "question": "What is the origin of the Semitendinosus?",
    "group": "Thigh",
    "answer": "Ischial tuberosity",
    "options": [
      "Superior 2/3 of anterior and lateral surfaces of femur",
      "Inferior ramus of pubis",
      "Anterior superior iliac spine"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_digitorum",
    "question": "What is the action of the Extensor Digitorum?",
    "group": "Forearm",
    "answer": "Extension at finger joints",
    "options": [
      "Flexion at joints at thumb",
      "Flexion at proximal interphalangeal, metacarpophalangeal, and wrist joints",
      "Extension at joints of little finger, extension at wrist"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-subclavius",
    "question": "What is the action of the Subclavius?",
    "group": "Shoulder",
    "answer": "Depresses and protracts shoulder",
    "options": [
      "Flexion, adduction, and medial rotation at shoulder",
      "Adducts and performs downward rotation of the scapula",
      "Extension, adduction, and medial rotation at shoulder"
    ]
  },
  {
    "id": "origin-Axial-axial-interspinales",
    "question": "What is the origin of the Interspinales?",
    "group": "Axial",
    "answer": "Spinous process",
    "options": [
      "Superior border of rib",
      "Transverse & costal processes of C2-C6",
      "Transverse process"
    ]
  },
  {
    "id": "action-Arm-arm-lateral_head_of_triceps_brachii",
    "question": "What is the action of the Lateral Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Extension at elbow",
    "options": [
      "Extension at elbow, extension and adduction at shoulder",
      "Flexion at elbow",
      "Flexion at elbow and shoulder, supination of forearm and hand"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-serratus_anterior",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-SerratusAnterior-9.png",
    "answer": "Serratus Anterior"
  },
  {
    "id": "action-Foot-foot-flexor_digiti_minimi_brevis",
    "question": "What is the action of the Flexor Digiti Minimi Brevis?",
    "group": "Foot",
    "answer": "Flexion at metatarsophalangeal joint of toe 5",
    "options": [
      "Abduction and flexion at metatarsophalangeal joint of toe 5",
      "Flexion at metatarsophalangeal joints; extension at interphalangeal joints of toes 2-5",
      "Flexion of proximal interphalangeal joints of toes 2-5"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-levator_scapulae",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-LevatorScapulae-5.png",
    "answer": "Levator Scapulae"
  },
  {
    "id": "identify-Forearm-forearm-extensor_pollicis_brevis",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorPollicisBrevis-5.png",
    "answer": "Extensor Pollicis Brevis"
  },
  {
    "id": "insertion-Arm-arm-brachialis",
    "question": "What is the insertion of the Brachialis?",
    "group": "Arm",
    "answer": "Ulnar tuberosity and coronoid process",
    "options": [
      "Olecranon of ulna",
      "Lateral aspect of styloid process of radius",
      "Radial tuberosity"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-pectoralis_major",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-PectoralisMajor-13.png",
    "answer": "Pectoralis Major"
  },
  {
    "id": "identify-Shoulder-shoulder-rhomboid_major",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-RhomboidMajor-5.png",
    "answer": "Rhomboid Major"
  },
  {
    "id": "insertion-Forearm-forearm-extensor_digitorum",
    "question": "What is the insertion of the Extensor Digitorum?",
    "group": "Forearm",
    "answer": "Posterior surfaces of phalanges of digits 2-5",
    "options": [
      "Base of second metacarpal bone",
      "Lateral surface of radius distal to the radial tuberosity",
      "Pisiform, hamate, and base of fifth metacarpal bones"
    ]
  },
  {
    "id": "origin-Leg-leg-medial_head_of_gastrocnemius",
    "question": "What is the origin of the Medial Head of Gastrocnemius?",
    "group": "Leg",
    "answer": "Medial condyle of femur",
    "options": [
      "Mid Lateral margin of fibula",
      "Lateral condyle of femur",
      "Medial surface of the fibula"
    ]
  },
  {
    "id": "insertion-Arm-arm-lateral_head_of_triceps_brachii",
    "question": "What is the insertion of the Lateral Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Olecranon of ulna",
    "options": [
      "Lateral aspect of styloid process of radius",
      "Radial tuberosity",
      "Ulnar tuberosity and coronoid process"
    ]
  },
  {
    "id": "action-Hip-hip-tensor_fasciae_latae",
    "question": "What is the action of the Tensor Fasciae Latae?",
    "group": "Hip",
    "answer": "Extension of the knee, lateral rotation of the leg",
    "options": [
      "Lateral rotation of hip",
      "Major extensor of hip joint, assists in laterally rotating the thigh",
      "Abduction and medial rotation at hip"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-extensor_pollicis_longus",
    "question": "What is the insertion of the Extensor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Base of distal phalanx of thumb",
    "options": [
      "Lateral margin of first metacarpal bone and trapezium",
      "Bases of second and third metacarpal bones",
      "Posterior surfaces of phalanges of digits 2-5"
    ]
  },
  {
    "id": "origin-Arm-arm-long_head_of_triceps_brachii",
    "question": "What is the origin of the Long Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Infraglenoid tubercle of scapula",
    "options": [
      "Supraglenoid tubercle",
      "Distal half of the anterior surface of humerus",
      "Ridge superior to lateral epicondyle of humerus"
    ]
  },
  {
    "id": "origin-Leg-leg-extensor_digitorum_longus",
    "question": "What is the origin of the Extensor Digitorum Longus?",
    "group": "Leg",
    "answer": "Lateral condyle of tibia| anterior surface of fibula",
    "options": [
      "Interosseous membrane and adjacent shafts of tibia and fibula",
      "Mid Lateral margin of fibula",
      "Lateral condyle of femur"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-levator_scapulae",
    "question": "What is the action of the Levator Scapulae?",
    "group": "Shoulder",
    "answer": "Elevates scapula",
    "options": [
      "Flexion and extension at arm, adducts the arm, medial and lateral rotation at the arm",
      "Elevate, retract, depress, or rotate scapula and/or clavicle",
      "Depresses and protracts shoulder"
    ]
  },
  {
    "id": "action-Thigh-thigh-adductor_magnus",
    "question": "What is the action of the Adductor Magnus?",
    "group": "Thigh",
    "answer": "Adducts and flexes the thigh, and helps to laterally rotate the hip joint, helps extend the thigh",
    "options": [
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Adducts and flexes the thigh",
      "Flexion at knee; abduction, flexion, and lateral rotation at hip"
    ]
  },
  {
    "id": "origin-Forearm-forearm-flexor_carpi_ulnaris",
    "question": "What is the origin of the Flexor Carpi Ulnaris?",
    "group": "Forearm",
    "answer": "Medial epicondyle of humerus",
    "options": [
      "Lateral supracondylar ridge of humerus",
      "Anterior and medial surfaces of distal ulna",
      "Shaft of radius; interosseous membrane"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_digiti_minimi",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorDigitiMinimi-5.png",
    "answer": "Extensor Digiti Minimi"
  },
  {
    "id": "insertion-Hip-hip-gluteus_maximus",
    "question": "What is the insertion of the Gluteus Maximus?",
    "group": "Hip",
    "answer": "Iliotibial tract, gluteal tuberosity",
    "options": [
      "Intertrochanteric crest of femur",
      "Greater trochanter of femur",
      "Iliotibial tract"
    ]
  },
  {
    "id": "action-Hand-hand-abductor_digiti_minimi",
    "question": "What is the action of the Abductor Digiti Minimi?",
    "group": "Hand",
    "answer": "Abduction of little finger and flexion at it's metacarpophalangeal joint",
    "options": [
      "Adduction at metacarpophalangeal joints of digits 2, 4, and 5; flexion at metacarpophalangeal joints; extension at interphalangeal joints",
      "Flexion and adduction of thumb",
      "Abduction of thumb"
    ]
  },
  {
    "id": "action-Foot-foot-lumbricals_(4)",
    "question": "What is the action of the Lumbricals (4)?",
    "group": "Foot",
    "answer": "Flexion at metatarsophalangeal joints; extension at interphalangeal joints of toes 2-5",
    "options": [
      "Extension at metatarsophalangeal joints of toes 1-4",
      "Abduction at metatarsophalangeal joints of great toe",
      "Flexion at metatarsophalangeal joint of great toe"
    ]
  },
  {
    "id": "action-Hand-hand-flexor_pollicis_brevis",
    "question": "What is the action of the Flexor Pollicis Brevis?",
    "group": "Hand",
    "answer": "Flexion and adduction of thumb",
    "options": [
      "Flexion at metacarpophalangeal joints; extension at proximal and distal interphalangeal joints",
      "Adduction of thumb",
      "Flexion at metacarpophalangeal joint"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-rhomboid_major",
    "question": "What is the action of the Rhomboid Major?",
    "group": "Shoulder",
    "answer": "Adducts and performs downward rotation of the scapula",
    "options": [
      "Extension, adduction, and medial rotation at shoulder",
      "Medial rotation at shoulder",
      "Flexion and extension at arm, adducts the arm, medial and lateral rotation at the arm"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-teres_major",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-TeresMajor-5.png",
    "answer": "Teres Major"
  },
  {
    "id": "origin-Forearm-forearm-abductor_pollicis_longus",
    "question": "What is the origin of the Abductor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Proximal dorsal surfaces of ulna and radius",
    "options": [
      "Medial and posterior surfaces of ulnar| medial surfaces of coronoid process; interosseous membrane",
      "Anterior and medial surfaces of distal ulna",
      "Shaft of radius; interosseous membrane"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-extensor_digiti_minimi",
    "question": "What is the insertion of the Extensor Digiti Minimi?",
    "group": "Forearm",
    "answer": "Posterior surface of proximal phalanx of little finger",
    "options": [
      "Lateral surface of radius distal to the radial tuberosity",
      "Palmar aponeurosis and flexor retinaculum",
      "Posterior surfaces of phalanges of digits 2-5"
    ]
  },
  {
    "id": "insertion-Arm-arm-short_head_of_biceps_brachii",
    "question": "What is the insertion of the Short Head of Biceps Brachii?",
    "group": "Arm",
    "answer": "Radial tuberosity",
    "options": [
      "Lateral aspect of styloid process of radius",
      "Ulnar tuberosity and coronoid process",
      "Olecranon of ulna"
    ]
  },
  {
    "id": "insertion-Axial-axial-internal_oblique",
    "question": "What is the insertion of the Internal Oblique?",
    "group": "Axial",
    "answer": "Inferior borders of ribs 8-12, linea alba",
    "options": [
      "Mastoid process",
      "External oblique aponeurosis",
      "Spinous processes of 3rd superior vertebrae"
    ]
  },
  {
    "id": "identify-Forearm-forearm-pronator_quadratus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-PronatorQuadratus-13.png",
    "answer": "Pronator Quadratus"
  },
  {
    "id": "action-Leg-leg-tibialis_anterior",
    "question": "What is the action of the Tibialis Anterior?",
    "group": "Leg",
    "answer": "Dorsiflexion at ankle, inversion of foot",
    "options": [
      "Plantar flexion at ankle, flexion at knee",
      "Plantar flexion at ankle",
      "Flexion at joints of toes 2-5, plantarflexion"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-rhomboid_minor",
    "question": "What is the action of the Rhomboid Minor?",
    "group": "Shoulder",
    "answer": "Adducts and performs downward rotation of the scapula",
    "options": [
      "Elevate, retract, depress, or rotate scapula and/or clavicle",
      "Depresses and shoulders shoulders, rotates scapula, elevates ribs",
      "Protracts shoulder, rotates scapula so glenoid cavity moves superiorly"
    ]
  },
  {
    "id": "origin-Arm-arm-short_head_of_biceps_brachii",
    "question": "What is the origin of the Short Head of Biceps Brachii?",
    "group": "Arm",
    "answer": "Coracoid process",
    "options": [
      "Ridge superior to lateral epicondyle of humerus",
      "Supraglenoid tubercle",
      "Distal half of the anterior surface of humerus"
    ]
  },
  {
    "id": "action-Thigh-thigh-iliacus",
    "question": "What is the action of the Iliacus?",
    "group": "Thigh",
    "answer": "Flexion at hip",
    "options": [
      "Adducts and flexes the thigh",
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Flexes the knee, adducts the thigh, and helps to medially rotate the tibia on the femur"
    ]
  },
  {
    "id": "origin-Axial-axial-sternocleidomastoid",
    "question": "What is the origin of the Sternocleidomastoid?",
    "group": "Axial",
    "answer": "Sternal end of clavicle| manubrium",
    "options": [
      "Transverse process",
      "Sacrum & transverse processes",
      "Transverse & costal processes of C2-C6"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-long_head_of_biceps_femoris",
    "question": "What is the insertion of the Long Head of Biceps Femoris?",
    "group": "Thigh",
    "answer": "Head of fibula",
    "options": [
      "Medial surface of tibial shaft near tibial tuberosity",
      "Pectineal line",
      "Lateral condyle of tibia"
    ]
  },
  {
    "id": "action-Foot-foot-quadratus_plantae",
    "question": "What is the action of the Quadratus Plantae?",
    "group": "Foot",
    "answer": "Flexion at joints of toes 2-5",
    "options": [
      "Abduction and flexion at metatarsophalangeal joint of toe 5",
      "Flexion at metatarsophalangeal joints; extension at interphalangeal joints of toes 2-5",
      "Flexion of proximal interphalangeal joints of toes 2-5"
    ]
  },
  {
    "id": "origin-Forearm-forearm-extensor_pollicis_longus",
    "question": "What is the origin of the Extensor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Posterior and lateral surfaces of ulna; interosseous membrane",
    "options": [
      "Posterior surface of ulna; interosseous membrane",
      "Medial epicondyle of humerus",
      "Lateral epicondyle of humerus and ridge near radial notch of ulna"
    ]
  },
  {
    "id": "insertion-Leg-leg-extensor_hallucis_longus",
    "question": "What is the insertion of the Extensor Hallucis Longus?",
    "group": "Leg",
    "answer": "Superior surface of phalanges, distal phalanx of great toe",
    "options": [
      "Inferior surface, distal phalanx of great toe",
      "Base of fifth metatarsal bone",
      "Inferior surface of distal phalanges, toes 2-5"
    ]
  },
  {
    "id": "action-Leg-leg-plantaris",
    "question": "What is the action of the Plantaris?",
    "group": "Leg",
    "answer": "Plantar flexion at ankle, flexion at knee",
    "options": [
      "Inversion of foot, plantar flexion at ankle",
      "Dorsiflexion at ankle, inversion of foot",
      "Extension at joints of great toe, dorsiflexion at ankle"
    ]
  },
  {
    "id": "action-Thigh-thigh-adductor_longus",
    "question": "What is the action of the Adductor Longus?",
    "group": "Thigh",
    "answer": "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
    "options": [
      "Flexion at knee; abduction, flexion, and lateral rotation at hip",
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint, helps extend the thigh",
      "Lesser trochanter"
    ]
  },
  {
    "id": "action-Leg-leg-flexor_digitorum_longus",
    "question": "What is the action of the Flexor Digitorum Longus?",
    "group": "Leg",
    "answer": "Flexion at joints of toes 2-5, plantarflexion",
    "options": [
      "Dorsiflexion at ankle, eversion of foot",
      "Plantar flexion at ankle, flexion at knee",
      "Plantar flexion at ankle"
    ]
  },
  {
    "id": "insertion-Leg-leg-tibialis_posterior",
    "question": "What is the insertion of the Tibialis Posterior?",
    "group": "Leg",
    "answer": "Navicular,  all three cuneiforms, cuboid, second, third, and fourth metatarsal bones",
    "options": [
      "Calcaneus via calcaneal tendon",
      "Superior surface of phalanges, toes 2-5",
      "Posterior portion of calcaneus"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_pollicis_longus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorPollicisLongus-5.png",
    "answer": "Extensor Pollicis Longus"
  },
  {
    "id": "action-Axial-axial-iliocostalis_lumborum",
    "question": "What is the action of the Iliocostalis Lumborum?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion",
    "options": [
      "Rotation of vertebrae",
      "Expands thoracic cavity",
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-gracilis",
    "question": "What is the insertion of the Gracilis?",
    "group": "Thigh",
    "answer": "Medial surface of tibial shaft",
    "options": [
      "Lateral condyle of tibia",
      "Posterior surface of medial condyle of tibia",
      "Proximal, medial surface of tibia"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-vastus_lateralis",
    "question": "What is the insertion of the Vastus Lateralis?",
    "group": "Thigh",
    "answer": "Tibial tuberosity",
    "options": [
      "Proximal, medial surface of tibia",
      "Lesser trochanter",
      "Pectineal line"
    ]
  },
  {
    "id": "identify-Leg-leg-tibialis_anterior",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-TibialisAnterior-13.png",
    "answer": "Tibialis Anterior"
  },
  {
    "id": "action-Axial-axial-quadratus_lumborum",
    "question": "What is the action of the Quadratus Lumborum?",
    "group": "Axial",
    "answer": "Depresses 12th rib, lateral flexion",
    "options": [
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation",
      "Rotation of vertebrae",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation"
    ]
  },
  {
    "id": "action-Leg-leg-flexor_hallucis_longus",
    "question": "What is the action of the Flexor Hallucis Longus?",
    "group": "Leg",
    "answer": "Flexion at joints of great toe, plantar flexion",
    "options": [
      "Inversion of foot, plantar flexion at ankle",
      "Dorsiflexion at ankle, inversion of foot",
      "Flexion at joints of toes 2-5, plantarflexion"
    ]
  },
  {
    "id": "action-Foot-foot-adductor_hallucis",
    "question": "What is the action of the Adductor Hallucis?",
    "group": "Foot",
    "answer": "Adduction and flexion at metatarsophalangeal joint of great toe",
    "options": [
      "Abduction and flexion at metatarsophalangeal joint of toe 5",
      "Flexion at metatarsophalangeal joint of great toe",
      "Abduction at metatarsophalangeal joints of great toe"
    ]
  },
  {
    "id": "action-Axial-axial-rotatores",
    "question": "What is the action of the Rotatores?",
    "group": "Axial",
    "answer": "Rotation of vertebrae",
    "options": [
      "Expands thoracic cavity",
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: contralateral rotation"
    ]
  },
  {
    "id": "action-Hand-hand-adductor_pollicis",
    "question": "What is the action of the Adductor Pollicis?",
    "group": "Hand",
    "answer": "Adduction of thumb",
    "options": [
      "Abduction of thumb",
      "Opposition of thumb",
      "Moves skin on medial border toward midline of palm"
    ]
  },
  {
    "id": "action-Forearm-forearm-anconeus",
    "question": "What is the action of the Anconeus?",
    "group": "Forearm",
    "answer": "Extension at elbow",
    "options": [
      "Flexion at distal interphalangeal joints",
      "Flexion at wrist",
      "Extension and adduction at joints of index finger"
    ]
  },
  {
    "id": "action-Thigh-thigh-rectus_femoris",
    "question": "What is the action of the Rectus Femoris?",
    "group": "Thigh",
    "answer": "Extension at knee, flexion at hip",
    "options": [
      "Flexes the knee, adducts the thigh, and helps to medially rotate the tibia on the femur",
      "Flexion at hip",
      "Flexion at knee; abduction, flexion, and lateral rotation at hip"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-teres_major",
    "question": "What is the action of the Teres Major?",
    "group": "Shoulder",
    "answer": "Medial rotation at shoulder",
    "options": [
      "Lateral rotation at shoulder",
      "Extension, adduction, and medial rotation at shoulder",
      "Lateral rotation and adduction at shoulder"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-rhomboid_minor",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-RhomboidMinor-5.png",
    "answer": "Rhomboid Minor"
  },
  {
    "id": "insertion-Shoulder-shoulder-serratus_anterior",
    "question": "What is the insertion of the Serratus Anterior?",
    "group": "Shoulder",
    "answer": "Anterior surface of vertebral border of scapula",
    "options": [
      "Greater tubercle of humerus",
      "Medial lip of intertubercular sulcus of humerus",
      "Deltoid tuberosity of humerus"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-subscapularis",
    "question": "What is the origin of the Subscapularis?",
    "group": "Shoulder",
    "answer": "Subscapular fossa of scapula",
    "options": [
      "Transverse process of C1-C4",
      "Infraspinous fossa of scapula",
      "Inferior angle of scapula"
    ]
  },
  {
    "id": "origin-Axial-axial-multifidus",
    "question": "What is the origin of the Multifidus?",
    "group": "Axial",
    "answer": "Sacrum & transverse processes",
    "options": [
      "Transverse & costal processes of C2-C6",
      "Sternal end of clavicle| manubrium",
      "Superior pubis"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-pectoralis_major",
    "question": "What is the insertion of the Pectoralis Major?",
    "group": "Shoulder",
    "answer": "Crest of greater tubercle and lateral lip of intertubercular sulcus of humerus ",
    "options": [
      "Deltoid tuberosity of humerus",
      "Clavicle and scapula",
      "Vertebral border of scapula"
    ]
  },
  {
    "id": "identify-Forearm-forearm-pronator_teres",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-PronatorTeres-13.png",
    "answer": "Pronator Teres"
  },
  {
    "id": "action-Forearm-forearm-flexor_pollicis_longus",
    "question": "What is the action of the Flexor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Flexion at joints at thumb",
    "options": [
      "Extension at finger joints",
      "Flexion at proximal interphalangeal, metacarpophalangeal, and wrist joints",
      "Extension at joints of thumb, abduction at wrist"
    ]
  },
  {
    "id": "action-Thigh-thigh-semimembranosus",
    "question": "What is the action of the Semimembranosus?",
    "group": "Thigh",
    "answer": "Flexion at knee",
    "options": [
      "Flexion at knee; abduction, flexion, and lateral rotation at hip",
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Extension at knee, flexion at hip"
    ]
  },
  {
    "id": "origin-Leg-leg-tibialis_posterior",
    "question": "What is the origin of the Tibialis Posterior?",
    "group": "Leg",
    "answer": "Interosseous membrane and adjacent shafts of tibia and fibula",
    "options": [
      "Posterior surface of fibula",
      "Posteromedial surface of tibia",
      "Head and proximal shaft of fibula"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_indicis",
    "question": "What is the action of the Extensor Indicis?",
    "group": "Forearm",
    "answer": "Extension and adduction at joints of index finger",
    "options": [
      "Flexion at distal interphalangeal joints",
      "Flexion and abduction at wrist",
      "Flexion at wrist"
    ]
  },
  {
    "id": "action-Hip-hip-gluteus_medius",
    "question": "What is the action of the Gluteus Medius?",
    "group": "Hip",
    "answer": "Abduction and medial rotation at hip",
    "options": [
      "Lateral rotation and abduction of hip",
      "Lateral rotation of hip",
      "Major extensor of hip joint, assists in laterally rotating the thigh"
    ]
  },
  {
    "id": "origin-Axial-axial-rectus_abdominis",
    "question": "What is the origin of the Rectus Abdominis?",
    "group": "Axial",
    "answer": "Superior pubis",
    "options": [
      "Sacrum & transverse processes",
      "Xiphoid process| ribs 7-12 & costal cartilages",
      "Sternal end of clavicle| manubrium"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-teres_major",
    "question": "What is the origin of the Teres Major?",
    "group": "Shoulder",
    "answer": "Inferior angle of scapula",
    "options": [
      "Transverse process of C1-C4",
      "3rd to 5th ribs near their costal cartilages",
      "Cartilages of ribs 2-6| body of sternum| and inferior| medial portion of clavicle"
    ]
  },
  {
    "id": "action-Hip-hip-inferior_gemellus",
    "question": "What is the action of the Inferior Gemellus?",
    "group": "Hip",
    "answer": "Lateral rotation and abduction of hip",
    "options": [
      "Abduction and medial rotation at hip",
      "Lateral rotation of hip",
      "Extension of the knee, lateral rotation of the leg"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-subscapularis",
    "question": "What is the insertion of the Subscapularis?",
    "group": "Shoulder",
    "answer": "Lesser tubercle of humerus",
    "options": [
      "Coracoid process of scapula",
      "Floor of intertubercular sulcus of the humerus",
      "Clavicle and scapula"
    ]
  },
  {
    "id": "identify-Forearm-forearm-supinator",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-Supinator-5.png",
    "answer": "Supinator"
  },
  {
    "id": "identify-Arm-arm-long_head_of_biceps_brachii",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-LongBicepsBrachii-13.png",
    "answer": "Long Head of Biceps Brachii"
  },
  {
    "id": "origin-Thigh-thigh-vastus_intermedius",
    "question": "What is the origin of the Vastus Intermedius?",
    "group": "Thigh",
    "answer": "Superior 2/3 of anterior and lateral surfaces of femur",
    "options": [
      "Iliac fossa",
      "Anterior surfaces and lower borders of transverse processes of L1 - L5 and bodies and discs of T12 - L5",
      "Anterior inferior iliac spine"
    ]
  },
  {
    "id": "identify-Arm-arm-coracobrachialis",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-Coracobrachialis-13.png",
    "answer": "Coracobrachialis"
  },
  {
    "id": "action-Hand-hand-lumbrical_(4)",
    "question": "What is the action of the Lumbrical (4)?",
    "group": "Hand",
    "answer": "Flexion at metacarpophalangeal joints; extension at proximal and distal interphalangeal joints",
    "options": [
      "Abduction at metacarpophalangeal joints of digits 2-4, flexion at metacarpophalangeal joints; extension at interphalangeal joints",
      "Abduction of thumb",
      "Moves skin on medial border toward midline of palm"
    ]
  },
  {
    "id": "origin-Axial-axial-intertransversarii",
    "question": "What is the origin of the Intertransversarii?",
    "group": "Axial",
    "answer": "Transverse process",
    "options": [
      "Iliac crest",
      "",
      "Sacrum & transverse processes"
    ]
  },
  {
    "id": "action-Thigh-thigh-long_head_of_biceps_femoris",
    "question": "What is the action of the Long Head of Biceps Femoris?",
    "group": "Thigh",
    "answer": "Flexion at knee",
    "options": [
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint, helps extend the thigh",
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-rhomboid_minor",
    "question": "What is the insertion of the Rhomboid Minor?",
    "group": "Shoulder",
    "answer": "Vertebral border of scapula",
    "options": [
      "Floor of intertubercular sulcus of the humerus",
      "Clavicle and scapula",
      "Coracoid process of scapula"
    ]
  },
  {
    "id": "insertion-Axial-axial-rotatores",
    "question": "What is the insertion of the Rotatores?",
    "group": "Axial",
    "answer": "Spinous process",
    "options": [
      "Transverse process",
      "Spinous processes of 3rd superior vertebrae",
      "Inferior border of superior rib"
    ]
  },
  {
    "id": "identify-Arm-arm-long_head_of_triceps_brachii",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-LongTricepsBrachii-5.png",
    "answer": "Long Head of Triceps Brachii"
  },
  {
    "id": "origin-Hip-hip-inferior_gemellus",
    "question": "What is the origin of the Inferior Gemellus?",
    "group": "Hip",
    "answer": "Ischial tuberosity ",
    "options": [
      "Anterior iliac crest",
      "Ischial tuberosity",
      "Iliac crest| posterior gluteal line| lateral surface of ilium"
    ]
  },
  {
    "id": "insertion-Axial-axial-internal_intercostals",
    "question": "What is the insertion of the Internal Intercostals?",
    "group": "Axial",
    "answer": "Inferior border of superior rib",
    "options": [
      "Costal cartilages of ribs 5-7, xiphoid process",
      "Ribs 1 & 2",
      "External oblique aponeurosis"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-supraspinatus",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-Supraspinatus-5.png",
    "answer": "Supraspinatus"
  },
  {
    "id": "insertion-Arm-arm-long_head_of_biceps_brachii",
    "question": "What is the insertion of the Long Head of Biceps Brachii?",
    "group": "Arm",
    "answer": "Radial tuberosity",
    "options": [
      "Olecranon of ulna",
      "Ulnar tuberosity and coronoid process",
      "Lateral aspect of styloid process of radius"
    ]
  },
  {
    "id": "origin-Thigh-thigh-adductor_longus",
    "question": "What is the origin of the Adductor Longus?",
    "group": "Thigh",
    "answer": "Inferior ramus of pubis",
    "options": [
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
      "Linea aspera",
      "Superior 2/3 of anterior and lateral surfaces of femur"
    ]
  },
  {
    "id": "identify-Thigh-thigh-vastus_medialis",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-VastusMedialis-13.png",
    "answer": "Vastus Medialis"
  },
  {
    "id": "insertion-Shoulder-shoulder-latissimus_dorsi",
    "question": "What is the insertion of the Latissimus Dorsi?",
    "group": "Shoulder",
    "answer": "Floor of intertubercular sulcus of the humerus",
    "options": [
      "Deltoid tuberosity of humerus",
      "Crest of greater tubercle and lateral lip of intertubercular sulcus of humerus ",
      "Greater tubercle of humerus"
    ]
  },
  {
    "id": "action-Thigh-thigh-semitendinosus",
    "question": "What is the action of the Semitendinosus?",
    "group": "Thigh",
    "answer": "Flexion at knee",
    "options": [
      "Flexes the knee, adducts the thigh, and helps to medially rotate the tibia on the femur",
      "Adducts and flexes the thigh",
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-subscapularis",
    "question": "What is the action of the Subscapularis?",
    "group": "Shoulder",
    "answer": "Medial rotation at shoulder",
    "options": [
      "Abduction at shoulder",
      "Elevates scapula",
      "Elevate, retract, depress, or rotate scapula and/or clavicle"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-trapezius",
    "question": "What is the insertion of the Trapezius?",
    "group": "Shoulder",
    "answer": "Clavicle and scapula",
    "options": [
      "Crest of greater tubercle and lateral lip of intertubercular sulcus of humerus ",
      "Medial lip of intertubercular sulcus of humerus",
      "Deltoid tuberosity of humerus"
    ]
  },
  {
    "id": "action-Axial-axial-spinalis_cervicis",
    "question": "What is the action of the Spinalis Cervicis?",
    "group": "Axial",
    "answer": "Bilateral: extension",
    "options": [
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
      "Bilateral: extension; Unilateral: lateral flexion",
      "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation"
    ]
  },
  {
    "id": "action-Foot-foot-abductor_digiti_minimi",
    "question": "What is the action of the Abductor Digiti Minimi?",
    "group": "Foot",
    "answer": "Abduction and flexion at metatarsophalangeal joint of toe 5",
    "options": [
      "Flexion at metatarsophalangeal joint of toe 5",
      "Flexion at joints of toes 2-5",
      "Flexion at metatarsophalangeal joints; extension at interphalangeal joints of toes 2-5"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-serratus_anterior",
    "question": "What is the action of the Serratus Anterior?",
    "group": "Shoulder",
    "answer": "Protracts shoulder, rotates scapula so glenoid cavity moves superiorly",
    "options": [
      "Flexion, adduction, and medial rotation at shoulder",
      "Depresses and shoulders shoulders, rotates scapula, elevates ribs",
      "Abduction at shoulder"
    ]
  },
  {
    "id": "origin-Axial-axial-diaphragm",
    "question": "What is the origin of the Diaphragm?",
    "group": "Axial",
    "answer": "Xiphoid process| ribs 7-12 & costal cartilages",
    "options": [
      "Sacrum & transverse processes",
      "Superior pubis",
      "Spinous process"
    ]
  },
  {
    "id": "action-Forearm-forearm-palmaris_longus",
    "question": "What is the action of the Palmaris Longus?",
    "group": "Forearm",
    "answer": "Flexion at wrist",
    "options": [
      "Extension at finger joints",
      "Flexion and abduction at wrist",
      "Extension at elbow"
    ]
  },
  {
    "id": "insertion-Leg-leg-fibularis_longus",
    "question": "What is the insertion of the Fibularis Longus?",
    "group": "Leg",
    "answer": "Base of first metatarsal bone and medial cuneiform",
    "options": [
      "Base of fifth metatarsal bone",
      "Navicular,  all three cuneiforms, cuboid, second, third, and fourth metatarsal bones",
      "Posterior portion of calcaneus"
    ]
  },
  {
    "id": "action-Thigh-thigh-gracilis",
    "question": "What is the action of the Gracilis?",
    "group": "Thigh",
    "answer": "Flexes the knee, adducts the thigh, and helps to medially rotate the tibia on the femur",
    "options": [
      "Extension at knee",
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Lesser trochanter"
    ]
  },
  {
    "id": "identify-Leg-leg-extensor_hallucis_longus",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-ExtensorHallucisLongus-13.png",
    "answer": "Extensor Hallucis Longus"
  },
  {
    "id": "origin-Thigh-thigh-vastus_medialis",
    "question": "What is the origin of the Vastus Medialis?",
    "group": "Thigh",
    "answer": "Linea aspera",
    "options": [
      "Iliac fossa",
      "Inferior ramus of pubis",
      "Ischial tuberosity"
    ]
  },
  {
    "id": "action-Leg-leg-lateral_head_of_gastrocnemius",
    "question": "What is the action of the Lateral Head of Gastrocnemius?",
    "group": "Leg",
    "answer": "Plantar flexion at ankle, flexion at knee",
    "options": [
      "Flexion at joints of great toe, plantar flexion",
      "Extension of toes 2-5, dorsiflexion at ankle",
      "Dorsiflexion at ankle, eversion of foot"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-deltoid",
    "question": "What is the origin of the Deltoid?",
    "group": "Shoulder",
    "answer": "Clavicle and scapula",
    "options": [
      "Subscapular fossa of scapula",
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12",
      "Spinous processes of C7-T1"
    ]
  },
  {
    "id": "origin-Thigh-thigh-iliacus",
    "question": "What is the origin of the Iliacus?",
    "group": "Thigh",
    "answer": "Iliac fossa",
    "options": [
      "Anterior surfaces and lower borders of transverse processes of L1 - L5 and bodies and discs of T12 - L5",
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
      "Anterior superior iliac spine"
    ]
  },
  {
    "id": "action-Axial-axial-longissimus_cervicis",
    "question": "What is the action of the Longissimus Cervicis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion",
    "options": [
      "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
      "Bilateral: extends; Unilateral: contralateral rotation",
      "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-deltoid",
    "question": "What is the action of the Deltoid?",
    "group": "Shoulder",
    "answer": "Flexion and extension at arm, adducts the arm, medial and lateral rotation at the arm",
    "options": [
      "Abduction at shoulder",
      "Extension, adduction, and medial rotation at shoulder",
      "Lateral rotation at shoulder"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-serratus_anterior",
    "question": "What is the origin of the Serratus Anterior?",
    "group": "Shoulder",
    "answer": "Anterior and superior margins or ribs 1-8 or 1-9",
    "options": [
      "Ligamentum nuchae and the spinous processes of T2-T5",
      "Infraspinous fossa of scapula",
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-pectoralis_minor",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-PectoralisMinor-13.png",
    "answer": "Pectoralis Minor"
  },
  {
    "id": "identify-Arm-arm-medial_head_of_triceps_brachii",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-MedialTricepsBrachii-5.png",
    "answer": "Medial Head of Triceps Brachii"
  },
  {
    "id": "action-Hand-hand-opponens_pollicis",
    "question": "What is the action of the Opponens Pollicis?",
    "group": "Hand",
    "answer": "Opposition of thumb",
    "options": [
      "Flexion at fifth metacarpophalangeal joint",
      "Abduction at metacarpophalangeal joints of digits 2-4, flexion at metacarpophalangeal joints; extension at interphalangeal joints",
      "Adduction at metacarpophalangeal joints of digits 2, 4, and 5; flexion at metacarpophalangeal joints; extension at interphalangeal joints"
    ]
  },
  {
    "id": "origin-Axial-axial-scalenes",
    "question": "What is the origin of the Scalenes?",
    "group": "Axial",
    "answer": "Transverse & costal processes of C2-C6",
    "options": [
      "Costal cartilages 7-12 ribs| iliac crest| thoracolumbar fascia",
      "Superior border of rib",
      "Spinous process"
    ]
  },
  {
    "id": "action-Axial-axial-semispinalis_capitis",
    "question": "What is the action of the Semispinalis Capitis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
    "options": [
      "Bilateral: extension; Unilateral: lateral flexion",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: contralateral rotation",
      "Flexes, compresses abdominal cavity"
    ]
  },
  {
    "id": "identify-Leg-leg-fibularis_brevis",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-FibularisBrevis-9.png",
    "answer": "Fibularis Brevis"
  },
  {
    "id": "action-Axial-axial-sternocleidomastoid",
    "question": "What is the action of the Sternocleidomastoid?",
    "group": "Axial",
    "answer": "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
    "options": [
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation",
      "Rotation of vertebrae",
      "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation"
    ]
  },
  {
    "id": "action-Axial-axial-iliocostalis_thoracis",
    "question": "What is the action of the Iliocostalis Thoracis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion",
    "options": [
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: contralateral rotation",
      "Bilateral: extension",
      "Compress abdominal cavity"
    ]
  },
  {
    "id": "action-Axial-axial-semispinalis_cervicis",
    "question": "What is the action of the Semispinalis Cervicis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
    "options": [
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: contralateral rotation",
      "Lateral flexion of vertebrae",
      "Bilateral: extends; Unilateral: contralateral rotation"
    ]
  },
  {
    "id": "origin-Thigh-thigh-pectineus",
    "question": "What is the origin of the Pectineus?",
    "group": "Thigh",
    "answer": "Superior ramus of pubis",
    "options": [
      "Ischial tuberosity",
      "Anterior superior iliac spine",
      "Superior 2/3 of anterior and lateral surfaces of femur"
    ]
  },
  {
    "id": "insertion-Arm-arm-long_head_of_triceps_brachii",
    "question": "What is the insertion of the Long Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Olecranon of ulna",
    "options": [
      "Ulnar tuberosity and coronoid process",
      "Lateral aspect of styloid process of radius",
      "Radial tuberosity"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-latissimus_dorsi",
    "question": "What is the action of the Latissimus Dorsi?",
    "group": "Shoulder",
    "answer": "Extension, adduction, and medial rotation at shoulder",
    "options": [
      "Elevates scapula",
      "Depresses and protracts shoulder",
      "Lateral rotation and adduction at shoulder"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_digitorum",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorDigitorum-5.png",
    "answer": "Extensor Digitorum"
  },
  {
    "id": "origin-Hip-hip-superior_gemellus",
    "question": "What is the origin of the Superior Gemellus?",
    "group": "Hip",
    "answer": "Ischial spine",
    "options": [
      "Iliac crest| posterior gluteal line| lateral surface of ilium",
      "Ischial tuberosity ",
      "Anterior iliac crest"
    ]
  },
  {
    "id": "identify-Forearm-forearm-abductor_pollicis_longus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-AbductorPollicisLongus-5.png",
    "answer": "Abductor Pollicis Longus"
  },
  {
    "id": "identify-Hip-hip-gluteus_maximus",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-GluteusMaximus-5.png",
    "answer": "Gluteus Maximus"
  },
  {
    "id": "identify-Shoulder-shoulder-infraspinatus",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-Infraspinatus-5.png",
    "answer": "Infraspinatus"
  },
  {
    "id": "action-Shoulder-shoulder-pectoralis_minor",
    "question": "What is the action of the Pectoralis Minor?",
    "group": "Shoulder",
    "answer": "Depresses and shoulders shoulders, rotates scapula, elevates ribs",
    "options": [
      "Protracts shoulder, rotates scapula so glenoid cavity moves superiorly",
      "Adducts and performs downward rotation of the scapula",
      "Elevates scapula"
    ]
  },
  {
    "id": "action-Thigh-thigh-pectineus",
    "question": "What is the action of the Pectineus?",
    "group": "Thigh",
    "answer": "Adducts and flexes the thigh",
    "options": [
      "Extension at knee",
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint, helps extend the thigh"
    ]
  },
  {
    "id": "identify-Thigh-thigh-psoas_major",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-PsoasMajor-13.png",
    "answer": "Psoas Major"
  },
  {
    "id": "identify-Arm-arm-brachialis",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-Brachialis-13.png",
    "answer": "Brachialis"
  },
  {
    "id": "action-Hand-hand-palmar_interosseous_(4)",
    "question": "What is the action of the Palmar Interosseous (4)?",
    "group": "Hand",
    "answer": "Adduction at metacarpophalangeal joints of digits 2, 4, and 5; flexion at metacarpophalangeal joints; extension at interphalangeal joints",
    "options": [
      "Adduction of thumb",
      "Flexion at metacarpophalangeal joint",
      "Opposition of thumb"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-pectineus",
    "question": "What is the insertion of the Pectineus?",
    "group": "Thigh",
    "answer": "Pectineal line",
    "options": [
      "Medial surface of tibial shaft",
      "Tibial tuberosity",
      "Linea aspera"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-infraspinatus",
    "question": "What is the action of the Infraspinatus?",
    "group": "Shoulder",
    "answer": "Lateral rotation at shoulder",
    "options": [
      "Depresses and protracts shoulder",
      "Extension, adduction, and medial rotation at shoulder",
      "Abduction at shoulder"
    ]
  },
  {
    "id": "identify-Thigh-thigh-gracilis",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-Gracilis-13.png",
    "answer": "Gracilis"
  },
  {
    "id": "insertion-Hip-hip-gluteus_medius",
    "question": "What is the insertion of the Gluteus Medius?",
    "group": "Hip",
    "answer": "Greater trochanter of femur",
    "options": [
      "Iliotibial tract, gluteal tuberosity",
      "Iliotibial tract",
      "Intertrochanteric crest of femur"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-deltoid",
    "question": "What is the insertion of the Deltoid?",
    "group": "Shoulder",
    "answer": "Deltoid tuberosity of humerus",
    "options": [
      "Vertebral border of scapula",
      "Anterior surface of vertebral border of scapula",
      "Crest of greater tubercle and lateral lip of intertubercular sulcus of humerus "
    ]
  },
  {
    "id": "identify-Forearm-forearm-flexor_digitorum_profundus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-FlexorDigitorumProfundus-13.png",
    "answer": "Flexor Digitorum Profundus"
  },
  {
    "id": "insertion-Forearm-forearm-extensor_carpi_ulnaris",
    "question": "What is the insertion of the Extensor Carpi Ulnaris?",
    "group": "Forearm",
    "answer": "Base of fifth metacarpal bone",
    "options": [
      "Lateral margin of first metacarpal bone and trapezium",
      "Anterolateral surface of distal portion of radius",
      "Base of proximal phalanx of thumb"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-rhomboid_minor",
    "question": "What is the origin of the Rhomboid Minor?",
    "group": "Shoulder",
    "answer": "Spinous processes of C7-T1",
    "options": [
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12",
      "Anterior and superior margins or ribs 1-8 or 1-9",
      "3rd to 5th ribs near their costal cartilages"
    ]
  },
  {
    "id": "action-Leg-leg-medial_head_of_gastrocnemius",
    "question": "What is the action of the Medial Head of Gastrocnemius?",
    "group": "Leg",
    "answer": "Plantar flexion at ankle, flexion at knee",
    "options": [
      "Inversion of foot, plantar flexion at ankle",
      "Extension at joints of great toe, dorsiflexion at ankle",
      "Flexion at joints of great toe, plantar flexion"
    ]
  },
  {
    "id": "insertion-Hip-hip-inferior_gemellus",
    "question": "What is the insertion of the Inferior Gemellus?",
    "group": "Hip",
    "answer": "Intertrochanteric crest of femur",
    "options": [
      "Iliotibial tract, gluteal tuberosity",
      "Greater trochanter of femur",
      "Iliotibial tract"
    ]
  },
  {
    "id": "action-Axial-axial-longissimus_capitis",
    "question": "What is the action of the Longissimus Capitis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion",
    "options": [
      "Compress abdominal cavity",
      "Lateral flexion of vertebrae",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation"
    ]
  },
  {
    "id": "origin-Axial-axial-internal_oblique",
    "question": "What is the origin of the Internal Oblique?",
    "group": "Axial",
    "answer": "Thoracolumbar fascia| iliac crest",
    "options": [
      "Sacrum & transverse processes",
      "Superior border of rib",
      "Iliac crest"
    ]
  },
  {
    "id": "action-Axial-axial-semispinalis_thoracis",
    "question": "What is the action of the Semispinalis Thoracis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
    "options": [
      "Bilateral: extension",
      "Bilateral: extension; Unilateral: lateral flexion",
      "Compress abdominal cavity"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-pectoralis_major",
    "question": "What is the origin of the Pectoralis Major?",
    "group": "Shoulder",
    "answer": "Cartilages of ribs 2-6| body of sternum| and inferior| medial portion of clavicle",
    "options": [
      "First rib",
      "Spinous processes of C7-T1",
      "Subscapular fossa of scapula"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-adductor_brevis",
    "question": "What is the insertion of the Adductor Brevis?",
    "group": "Thigh",
    "answer": "Linea aspera",
    "options": [
      "Tibial tuberosity",
      "Head of fibula",
      "Lesser trochanter"
    ]
  },
  {
    "id": "action-Thigh-thigh-vastus_medialis",
    "question": "What is the action of the Vastus Medialis?",
    "group": "Thigh",
    "answer": "Extension at knee",
    "options": [
      "Adducts and flexes the thigh, and helps to laterally rotate the thigh",
      "Flexion at knee; abduction, flexion, and lateral rotation at hip",
      "Extension at knee, flexion at hip"
    ]
  },
  {
    "id": "identify-Leg-leg-tibialis_posterior",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-TibialisPosterior-5.png",
    "answer": "Tibialis Posterior"
  },
  {
    "id": "origin-Axial-axial-quadratus_lumborum",
    "question": "What is the origin of the Quadratus Lumborum?",
    "group": "Axial",
    "answer": "Iliac crest",
    "options": [
      "Costal cartilages 7-12 ribs| iliac crest| thoracolumbar fascia",
      "Transverse process",
      ""
    ]
  },
  {
    "id": "insertion-Forearm-forearm-abductor_pollicis_longus",
    "question": "What is the insertion of the Abductor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Lateral margin of first metacarpal bone and trapezium",
    "options": [
      "Anterolateral surface of radius distal to the radial tuberosity",
      "Bases of second and third metacarpal bones",
      "Lateral margin of olecranon and ulnar shaft"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-teres_minor",
    "question": "What is the action of the Teres Minor?",
    "group": "Shoulder",
    "answer": "Lateral rotation and adduction at shoulder",
    "options": [
      "Extension, adduction, and medial rotation at shoulder",
      "Elevate, retract, depress, or rotate scapula and/or clavicle",
      "Adducts and performs downward rotation of the scapula"
    ]
  },
  {
    "id": "action-Forearm-forearm-pronator_teres",
    "question": "What is the action of the Pronator Teres?",
    "group": "Forearm",
    "answer": "Supinates forearm and hand",
    "options": [
      "Flexion and abduction at wrist",
      "Extension at finger joints",
      "Flexion at wrist"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-trapezius",
    "question": "What is the action of the Trapezius?",
    "group": "Shoulder",
    "answer": "Elevate, retract, depress, or rotate scapula and/or clavicle",
    "options": [
      "Flexion and extension at arm, adducts the arm, medial and lateral rotation at the arm",
      "Abduction at shoulder",
      "Flexion, adduction, and medial rotation at shoulder"
    ]
  },
  {
    "id": "origin-Forearm-forearm-pronator_teres",
    "question": "What is the origin of the Pronator Teres?",
    "group": "Forearm",
    "answer": "Medial epicondyle of humerus and coronoid process of ulna",
    "options": [
      "Medial epicondyle of humerus",
      "Anterior shaft of radius; interosseous membrane",
      "Posterior surface of ulna; interosseous membrane"
    ]
  },
  {
    "id": "origin-Arm-arm-coracobrachialis",
    "question": "What is the origin of the Coracobrachialis?",
    "group": "Arm",
    "answer": "Ridge superior to lateral epicondyle of humerus",
    "options": [
      "Superior| lateral margin of humerus",
      "Distal half of the anterior surface of humerus",
      "Infraglenoid tubercle of scapula"
    ]
  },
  {
    "id": "action-Shoulder-shoulder-supraspinatus",
    "question": "What is the action of the Supraspinatus?",
    "group": "Shoulder",
    "answer": "Abduction at shoulder",
    "options": [
      "Medial rotation at shoulder",
      "Elevates scapula",
      "Depresses and shoulders shoulders, rotates scapula, elevates ribs"
    ]
  },
  {
    "id": "action-Axial-axial-external_oblique",
    "question": "What is the action of the External Oblique?",
    "group": "Axial",
    "answer": "Bilateral: flexes, compresses, depresses ribs; Unilateral: contralateral rotation",
    "options": [
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
      "Elevate ribs; Bilateral: flexes the neck; Unilateral: Lateral flexion & ipsilateral rotation",
      "Lateral flexion of vertebrae"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-flexor_pollicis_longus",
    "question": "What is the insertion of the Flexor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Base of distal phalanx of thumb",
    "options": [
      "Posterior surfaces of phalanges of digits 2-5",
      "Base of distal phalanges of digits 2-5",
      "Posterior surface of proximal phalanx of little finger"
    ]
  },
  {
    "id": "action-Axial-axial-diaphragm",
    "question": "What is the action of the Diaphragm?",
    "group": "Axial",
    "answer": "Expands thoracic cavity",
    "options": [
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation",
      "Elevate ribs; Bilateral: flexes the neck; Unilateral: Lateral flexion & ipsilateral rotation",
      "Flexes, compresses abdominal cavity"
    ]
  },
  {
    "id": "identify-Forearm-forearm-flexor_digitorum_superficialis",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-FlexorDigitorumSuperficialis-13.png",
    "answer": "Flexor Digitorum Superficialis"
  },
  {
    "id": "insertion-Axial-axial-rectus_abdominis",
    "question": "What is the insertion of the Rectus Abdominis?",
    "group": "Axial",
    "answer": "Costal cartilages of ribs 5-7, xiphoid process",
    "options": [
      "Inferior border of superior rib",
      "Central tendon sheet",
      "Mastoid process"
    ]
  },
  {
    "id": "origin-Thigh-thigh-rectus_femoris",
    "question": "What is the origin of the Rectus Femoris?",
    "group": "Thigh",
    "answer": "Anterior inferior iliac spine",
    "options": [
      "Anterior superior iliac spine",
      "Linea aspera",
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera"
    ]
  },
  {
    "id": "identify-Hip-hip-tensor_fasciae_latae",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-TensorFasciaeLatae-15.png",
    "answer": "Tensor Fasciae Latae"
  },
  {
    "id": "identify-Thigh-thigh-sartorius",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-Sartorius-13.png",
    "answer": "Sartorius"
  },
  {
    "id": "action-Thigh-thigh-sartorius",
    "question": "What is the action of the Sartorius?",
    "group": "Thigh",
    "answer": "Flexion at knee; abduction, flexion, and lateral rotation at hip",
    "options": [
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
      "Lesser trochanter",
      "Flexion at hip"
    ]
  },
  {
    "id": "identify-Forearm-forearm-flexor_pollicis_longus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-FlexorPollicisLongus-13.png",
    "answer": "Flexor Pollicis Longus"
  },
  {
    "id": "insertion-Thigh-thigh-iliacus",
    "question": "What is the insertion of the Iliacus?",
    "group": "Thigh",
    "answer": "Lesser trochanter",
    "options": [
      "Proximal, medial surface of tibia",
      "Linea aspera and medial tubercle of femur",
      "Head of fibula"
    ]
  },
  {
    "id": "identify-Thigh-thigh-vastus_lateralis",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-VastusLateralis-13.png",
    "answer": "Vastus Lateralis"
  },
  {
    "id": "insertion-Leg-leg-extensor_digitorum_longus",
    "question": "What is the insertion of the Extensor Digitorum Longus?",
    "group": "Leg",
    "answer": "Superior surface of phalanges, toes 2-5",
    "options": [
      "Inferior surface of distal phalanges, toes 2-5",
      "Superior surface of phalanges, distal phalanx of great toe",
      "Inferior surface, distal phalanx of great toe"
    ]
  },
  {
    "id": "insertion-Axial-axial-external_oblique",
    "question": "What is the insertion of the External Oblique?",
    "group": "Axial",
    "answer": "External oblique aponeurosis",
    "options": [
      "Mastoid process",
      "Ribs 1 & 2",
      "Spinous processes of 3rd superior vertebrae"
    ]
  },
  {
    "id": "action-Hip-hip-gluteus_maximus",
    "question": "What is the action of the Gluteus Maximus?",
    "group": "Hip",
    "answer": "Major extensor of hip joint, assists in laterally rotating the thigh",
    "options": [
      "Lateral rotation and abduction of hip",
      "Abduction and medial rotation at hip",
      "Extension of the knee, lateral rotation of the leg"
    ]
  },
  {
    "id": "identify-Thigh-thigh-pectineus",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-Pectineus-13.png",
    "answer": "Pectineus"
  },
  {
    "id": "insertion-Hip-hip-obturator_externus",
    "question": "What is the insertion of the Obturator Externus?",
    "group": "Hip",
    "answer": "Intertrochanteric crest of femur",
    "options": [
      "Greater trochanter of femur",
      "Iliotibial tract, gluteal tuberosity",
      "Iliotibial tract"
    ]
  },
  {
    "id": "origin-Axial-axial-rotatores",
    "question": "What is the origin of the Rotatores?",
    "group": "Axial",
    "answer": "Transverse process",
    "options": [
      "External & inferior borders of ribs 5-12",
      "Costal cartilages 7-12 ribs| iliac crest| thoracolumbar fascia",
      "Sternal end of clavicle| manubrium"
    ]
  },
  {
    "id": "origin-Forearm-forearm-pronator_quadratus",
    "question": "What is the origin of the Pronator Quadratus?",
    "group": "Forearm",
    "answer": "Anterior and medial surfaces of distal ulna",
    "options": [
      "Medial epicondyle of humerus",
      "Lateral epicondyle of humerus",
      "Posterior and lateral surfaces of ulna; interosseous membrane"
    ]
  },
  {
    "id": "origin-Hip-hip-quadratus_femoris",
    "question": "What is the origin of the Quadratus Femoris?",
    "group": "Hip",
    "answer": "Ischial tuberosity",
    "options": [
      "Anterior iliac crest",
      "Iliac crest| posterior gluteal line| lateral surface of ilium",
      "Ischial spine"
    ]
  },
  {
    "id": "identify-Hip-hip-obturator_internus",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-ObturatorInternus-5.png",
    "answer": "Obturator Internus"
  },
  {
    "id": "action-Axial-axial-intertransversarii",
    "question": "What is the action of the Intertransversarii?",
    "group": "Axial",
    "answer": "Lateral flexion of vertebrae",
    "options": [
      "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation",
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation"
    ]
  },
  {
    "id": "origin-Arm-arm-medial_head_of_triceps_brachii",
    "question": "What is the origin of the Medial Head of Triceps Brachii?",
    "group": "Arm",
    "answer": "Posterior surface of humerus| inferior to radial groove",
    "options": [
      "Ridge superior to lateral epicondyle of humerus",
      "Infraglenoid tubercle of scapula",
      "Coracoid process"
    ]
  },
  {
    "id": "origin-Leg-leg-fibularis_tertius",
    "question": "What is the origin of the Fibularis Tertius?",
    "group": "Leg",
    "answer": "Medial surface of the fibula",
    "options": [
      "Posterior surface of fibula",
      "Lateral condyle of femur",
      "Anterior surface of fibula"
    ]
  },
  {
    "id": "origin-Thigh-thigh-long_head_of_biceps_femoris",
    "question": "What is the origin of the Long Head of Biceps Femoris?",
    "group": "Thigh",
    "answer": "Ischial tuberosity",
    "options": [
      "Anterior surfaces and lower borders of transverse processes of L1 - L5 and bodies and discs of T12 - L5",
      "Anterior superior iliac spine",
      "Superior ramus of pubis"
    ]
  },
  {
    "id": "action-Hand-hand-opponens_digiti_minimi",
    "question": "What is the action of the Opponens Digiti Minimi?",
    "group": "Hand",
    "answer": "Flexion at metacarpophalangeal joint",
    "options": [
      "Adduction at metacarpophalangeal joints of digits 2, 4, and 5; flexion at metacarpophalangeal joints; extension at interphalangeal joints",
      "Moves skin on medial border toward midline of palm",
      "Flexion at fifth metacarpophalangeal joint"
    ]
  },
  {
    "id": "origin-Axial-axial-transverse_abdominis",
    "question": "What is the origin of the Transverse Abdominis?",
    "group": "Axial",
    "answer": "Costal cartilages 7-12 ribs| iliac crest| thoracolumbar fascia",
    "options": [
      "Sternal end of clavicle| manubrium",
      "Superior border of rib",
      "Iliac crest"
    ]
  },
  {
    "id": "origin-Forearm-forearm-extensor_digiti_minimi",
    "question": "What is the origin of the Extensor Digiti Minimi?",
    "group": "Forearm",
    "answer": "Lateral epicondyle of humerus",
    "options": [
      "Medial epicondyle of humerus",
      "Medial and posterior surfaces of ulnar| medial surfaces of coronoid process; interosseous membrane",
      "Lateral epicondyle of humerus and ridge near radial notch of ulna"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-extensor_carpi_radialis_longus",
    "question": "What is the insertion of the Extensor Carpi Radialis Longus?",
    "group": "Forearm",
    "answer": "Base of second metacarpal bone",
    "options": [
      "Anterolateral surface of distal portion of radius",
      "Pisiform, hamate, and base of fifth metacarpal bones",
      "Posterior surface of proximal phalanx of little finger"
    ]
  },
  {
    "id": "action-Hip-hip-obturator_externus",
    "question": "What is the action of the Obturator Externus?",
    "group": "Hip",
    "answer": "Lateral rotation and abduction of hip",
    "options": [
      "Lateral rotation of hip",
      "Major extensor of hip joint, assists in laterally rotating the thigh",
      "Abduction and medial rotation at hip"
    ]
  },
  {
    "id": "action-Forearm-forearm-flexor_carpi_radialis",
    "question": "What is the action of the Flexor Carpi Radialis?",
    "group": "Forearm",
    "answer": "Flexion and abduction at wrist",
    "options": [
      "Flexion at joints at thumb",
      "Extension and abduction at wrist",
      "Extension at joints of thumb, abduction at wrist"
    ]
  },
  {
    "id": "origin-Hip-hip-gluteus_maximus",
    "question": "What is the origin of the Gluteus Maximus?",
    "group": "Hip",
    "answer": "Iliac crest| posterior gluteal line| lateral surface of ilium",
    "options": [
      "Anterior iliac crest",
      "Lateral surface of ilium between inferior and anterior gluteal lines",
      "Obturator foramen"
    ]
  },
  {
    "id": "identify-Arm-arm-short_head_of_biceps_brachii",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-ShortBicepsBrachii-13.png",
    "answer": "Short Head of Biceps Brachii"
  },
  {
    "id": "origin-Forearm-forearm-extensor_digitorum",
    "question": "What is the origin of the Extensor Digitorum?",
    "group": "Forearm",
    "answer": "Lateral epicondyle of humerus",
    "options": [
      "Medial epicondyle of humerus and coronoid process of ulna",
      "Proximal dorsal surfaces of ulna and radius",
      "Medial epicondyle of humerus"
    ]
  },
  {
    "id": "identify-Thigh-thigh-adductor_magnus",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-AdductorMagnus-13.png",
    "answer": "Adductor Magnus"
  },
  {
    "id": "action-Hip-hip-gluteus_minimus",
    "question": "What is the action of the Gluteus Minimus?",
    "group": "Hip",
    "answer": "Abduction and medial rotation at hip",
    "options": [
      "Lateral rotation and abduction of hip",
      "Extension of the knee, lateral rotation of the leg",
      "Major extensor of hip joint, assists in laterally rotating the thigh"
    ]
  },
  {
    "id": "insertion-Shoulder-shoulder-teres_minor",
    "question": "What is the insertion of the Teres Minor?",
    "group": "Shoulder",
    "answer": "Greater tubercle of humerus",
    "options": [
      "Medial lip of intertubercular sulcus of humerus",
      "Lesser tubercle of humerus",
      "Deltoid tuberosity of humerus"
    ]
  },
  {
    "id": "action-Hand-hand-flexor_digiti_minimi_brevis",
    "question": "What is the action of the Flexor Digiti Minimi Brevis?",
    "group": "Hand",
    "answer": "Flexion at fifth metacarpophalangeal joint",
    "options": [
      "Abduction at metacarpophalangeal joints of digits 2-4, flexion at metacarpophalangeal joints; extension at interphalangeal joints",
      "Flexion at metacarpophalangeal joints; extension at proximal and distal interphalangeal joints",
      "Adduction at metacarpophalangeal joints of digits 2, 4, and 5; flexion at metacarpophalangeal joints; extension at interphalangeal joints"
    ]
  },
  {
    "id": "origin-Forearm-forearm-flexor_carpi_radialis",
    "question": "What is the origin of the Flexor Carpi Radialis?",
    "group": "Forearm",
    "answer": "Medial epicondyle of humerus",
    "options": [
      "Medial epicondyle of humerus and coronoid process of ulna",
      "Proximal dorsal surfaces of ulna and radius",
      "Posterior and lateral surfaces of ulna; interosseous membrane"
    ]
  },
  {
    "id": "insertion-Axial-axial-scalenes",
    "question": "What is the insertion of the Scalenes?",
    "group": "Axial",
    "answer": "Ribs 1 & 2",
    "options": [
      "Linea alba",
      "Central tendon sheet",
      "Inferior border of superior rib"
    ]
  },
  {
    "id": "insertion-Leg-leg-flexor_hallucis_longus",
    "question": "What is the insertion of the Flexor Hallucis Longus?",
    "group": "Leg",
    "answer": "Inferior surface, distal phalanx of great toe",
    "options": [
      "Calcaneus via calcaneal tendon",
      "Base of first metatarsal bone and medial cuneiform",
      "Posterior portion of calcaneus"
    ]
  },
  {
    "id": "identify-Forearm-forearm-flexor_carpi_radialis",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-FlexorCarpiUlnaris-4.png",
    "answer": "Flexor Carpi Radialis"
  },
  {
    "id": "insertion-Forearm-forearm-pronator_teres",
    "question": "What is the insertion of the Pronator Teres?",
    "group": "Forearm",
    "answer": "Lateral surface of radius distal to the radial tuberosity",
    "options": [
      "Pisiform, hamate, and base of fifth metacarpal bones",
      "Anterolateral surface of distal portion of radius",
      "Base of distal phalanges of digits 2-5"
    ]
  },
  {
    "id": "identify-Leg-leg-soleus",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-Soleus-5.png",
    "answer": "Soleus"
  },
  {
    "id": "insertion-Leg-leg-plantaris",
    "question": "What is the insertion of the Plantaris?",
    "group": "Leg",
    "answer": "Posterior portion of calcaneus",
    "options": [
      "Calcaneus via calcaneal tendon",
      "Navicular,  all three cuneiforms, cuboid, second, third, and fourth metatarsal bones",
      "Inferior surface of distal phalanges, toes 2-5"
    ]
  },
  {
    "id": "action-Foot-foot-plantar_interossei_(3)",
    "question": "What is the action of the Plantar Interossei (3)?",
    "group": "Foot",
    "answer": "Adduction of metatarsophalangeal joints of toes 3-5; flexion of metatarsophalangeal joints and extension at interphalangeal joints",
    "options": [
      "Flexion at joints of toes 2-5",
      "Abduction and flexion at metatarsophalangeal joint of toe 5",
      "Flexion of proximal interphalangeal joints of toes 2-5"
    ]
  },
  {
    "id": "insertion-Leg-leg-fibularis_brevis",
    "question": "What is the insertion of the Fibularis Brevis?",
    "group": "Leg",
    "answer": "Base of fifth metatarsal bone",
    "options": [
      "Inferior surface of distal phalanges, toes 2-5",
      "Inferior surface, distal phalanx of great toe",
      "Dorsal surface of base of metatarsal bone 5"
    ]
  },
  {
    "id": "action-Hip-hip-quadratus_femoris",
    "question": "What is the action of the Quadratus Femoris?",
    "group": "Hip",
    "answer": "Lateral rotation of hip",
    "options": [
      "Major extensor of hip joint, assists in laterally rotating the thigh",
      "Abduction and medial rotation at hip",
      "Extension of the knee, lateral rotation of the leg"
    ]
  },
  {
    "id": "action-Foot-foot-flexor_digitorum_brevis",
    "question": "What is the action of the Flexor Digitorum Brevis?",
    "group": "Foot",
    "answer": "Flexion of proximal interphalangeal joints of toes 2-5",
    "options": [
      "Flexion at metatarsophalangeal joint of great toe",
      "Abduction at metatarsophalangeal joints of toes 3 and 4; flexion of metatarsophalangeal joints and extension at the interphalangeal joints of toes 2-4",
      "Adduction of metatarsophalangeal joints of toes 3-5; flexion of metatarsophalangeal joints and extension at interphalangeal joints"
    ]
  },
  {
    "id": "identify-Shoulder-shoulder-trapezius",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-Trapezius-5.png",
    "answer": "Trapezius"
  },
  {
    "id": "identify-Shoulder-shoulder-teres_minor",
    "group": "Shoulder",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Shoulder-TeresMinor-5.png",
    "answer": "Teres Minor"
  },
  {
    "id": "identify-Forearm-forearm-anconeus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-Anconeus-5.png",
    "answer": "Anconeus"
  },
  {
    "id": "origin-Forearm-forearm-extensor_carpi_radialis_longus",
    "question": "What is the origin of the Extensor Carpi Radialis Longus?",
    "group": "Forearm",
    "answer": "Lateral supracondylar ridge of humerus",
    "options": [
      "Posterior and lateral surfaces of ulna; interosseous membrane",
      "Posterior surface of ulna; interosseous membrane",
      "Posterior surface of lateral epicondyle of humerus"
    ]
  },
  {
    "id": "origin-Forearm-forearm-extensor_indicis",
    "question": "What is the origin of the Extensor Indicis?",
    "group": "Forearm",
    "answer": "Posterior surface of ulna; interosseous membrane",
    "options": [
      "Proximal dorsal surfaces of ulna and radius",
      "Posterior and lateral surfaces of ulna; interosseous membrane",
      "Medial and posterior surfaces of ulnar| medial surfaces of coronoid process; interosseous membrane"
    ]
  },
  {
    "id": "action-Arm-arm-short_head_of_biceps_brachii",
    "question": "What is the action of the Short Head of Biceps Brachii?",
    "group": "Arm",
    "answer": "Flexion at elbow and shoulder, supination of forearm and hand",
    "options": [
      "Extension at elbow, extension and adduction at shoulder",
      "Extension at elbow",
      "Flexion at elbow"
    ]
  },
  {
    "id": "action-Axial-axial-splenius_capitis",
    "question": "What is the action of the Splenius Capitis?",
    "group": "Axial",
    "answer": "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation",
    "options": [
      "Expands thoracic cavity",
      "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation"
    ]
  },
  {
    "id": "action-Axial-axial-multifidus",
    "question": "What is the action of the Multifidus?",
    "group": "Axial",
    "answer": "Bilateral: extends; Unilateral: contralateral rotation",
    "options": [
      "Bilateral: extension",
      "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
      "Flexes, compresses abdominal cavity"
    ]
  },
  {
    "id": "action-Hand-hand-dorsal_interosseous_(4)",
    "question": "What is the action of the Dorsal Interosseous (4)?",
    "group": "Hand",
    "answer": "Abduction at metacarpophalangeal joints of digits 2-4, flexion at metacarpophalangeal joints; extension at interphalangeal joints",
    "options": [
      "Flexion at metacarpophalangeal joint",
      "Flexion and adduction of thumb",
      "Adduction of thumb"
    ]
  },
  {
    "id": "action-Hand-hand-abductor_pollicis_brevis",
    "question": "What is the action of the Abductor Pollicis Brevis?",
    "group": "Hand",
    "answer": "Abduction of thumb",
    "options": [
      "Abduction of little finger and flexion at it's metacarpophalangeal joint",
      "Flexion at metacarpophalangeal joints; extension at proximal and distal interphalangeal joints",
      "Moves skin on medial border toward midline of palm"
    ]
  },
  {
    "id": "origin-Leg-leg-tibialis_anterior",
    "question": "What is the origin of the Tibialis Anterior?",
    "group": "Leg",
    "answer": "Lateral condyle and proximal shaft of tibia",
    "options": [
      "Lateral condyle of femur",
      "Medial condyle of femur",
      "Soleal line"
    ]
  },
  {
    "id": "identify-Arm-arm-lateral_head_of_triceps_brachii",
    "group": "Arm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Arm-LateralTricepsBrachii-5.png",
    "answer": "Lateral Head of Triceps Brachii"
  },
  {
    "id": "origin-Thigh-thigh-semimembranosus",
    "question": "What is the origin of the Semimembranosus?",
    "group": "Thigh",
    "answer": "Ischial tuberosity",
    "options": [
      "Linea aspera",
      "Inferior ramus of pubis",
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera"
    ]
  },
  {
    "id": "identify-Leg-leg-extensor_digitorum_longus",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-ExtensorDigitorumLongus-13.png",
    "answer": "Extensor Digitorum Longus"
  },
  {
    "id": "insertion-Leg-leg-tibialis_anterior",
    "question": "What is the insertion of the Tibialis Anterior?",
    "group": "Leg",
    "answer": "Base of first metatarsal bone and medial cuneiform",
    "options": [
      "Calcaneus via calcaneal tendon",
      "Posterior portion of calcaneus",
      "Superior surface of phalanges, toes 2-5"
    ]
  },
  {
    "id": "origin-Thigh-thigh-adductor_magnus",
    "question": "What is the origin of the Adductor Magnus?",
    "group": "Thigh",
    "answer": "Inferior ramus of pubis",
    "options": [
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
      "Iliac fossa",
      "Ischial tuberosity"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_carpi_ulnaris",
    "question": "What is the action of the Extensor Carpi Ulnaris?",
    "group": "Forearm",
    "answer": "Extension and abduction at wrist",
    "options": [
      "Flexion and abduction at wrist",
      "Flexion at wrist",
      "Adduction at joints of thumb and wrist"
    ]
  },
  {
    "id": "origin-Thigh-thigh-adductor_brevis",
    "question": "What is the origin of the Adductor Brevis?",
    "group": "Thigh",
    "answer": "Inferior ramus of pubis",
    "options": [
      "Anterior and inferior borders of greater trochanter| superior portion of lateral lip of linea aspera",
      "Ischial tuberosity",
      "Anterior inferior iliac spine"
    ]
  },
  {
    "id": "identify-Forearm-forearm-palmaris_longus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-PalmarisLongus-13.png",
    "answer": "Palmaris Longus"
  },
  {
    "id": "action-Leg-leg-fibularis_brevis",
    "question": "What is the action of the Fibularis Brevis?",
    "group": "Leg",
    "answer": "Eversion of foot and plantar flexion at ankle",
    "options": [
      "Flexion at joints of great toe, plantar flexion",
      "Plantar flexion at ankle",
      "Dorsiflexion at ankle, eversion of foot"
    ]
  },
  {
    "id": "origin-Hip-hip-piriformis",
    "question": "What is the origin of the Piriformis?",
    "group": "Hip",
    "answer": "Anterior surface of sacrum",
    "options": [
      "Anterior iliac crest",
      "Iliac crest| posterior gluteal line| lateral surface of ilium",
      "Obturator foramen"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-flexor_digitorum_profundus",
    "question": "What is the insertion of the Flexor Digitorum Profundus?",
    "group": "Forearm",
    "answer": "Base of distal phalanges of digits 2-5",
    "options": [
      "Bases of second and third metacarpal bones",
      "Palmar aponeurosis and flexor retinaculum",
      "Base of fifth metacarpal bone"
    ]
  },
  {
    "id": "action-Hip-hip-obturator_internus",
    "question": "What is the action of the Obturator Internus?",
    "group": "Hip",
    "answer": "Lateral rotation and abduction of hip",
    "options": [
      "Abduction and medial rotation at hip",
      "Extension of the knee, lateral rotation of the leg",
      "Lateral rotation of hip"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-psoas_major",
    "question": "What is the insertion of the Psoas Major?",
    "group": "Thigh",
    "answer": "Lesser trochanter",
    "options": [
      "Head of fibula",
      "Proximal, medial surface of tibia",
      "Linea aspera"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-short_head_of_biceps_femoris",
    "question": "What is the insertion of the Short Head of Biceps Femoris?",
    "group": "Thigh",
    "answer": "Lateral condyle of tibia",
    "options": [
      "Linea aspera",
      "Medial surface of tibial shaft near tibial tuberosity",
      "Tibial tuberosity"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_digiti_minimi",
    "question": "What is the action of the Extensor Digiti Minimi?",
    "group": "Forearm",
    "answer": "Extension at joints of little finger, extension at wrist",
    "options": [
      "Extension and abduction at wrist",
      "Flexion and abduction at wrist",
      "Flexion at proximal interphalangeal, metacarpophalangeal, and wrist joints"
    ]
  },
  {
    "id": "origin-Forearm-forearm-palmaris_longus",
    "question": "What is the origin of the Palmaris Longus?",
    "group": "Forearm",
    "answer": "Medial epicondyle of humerus",
    "options": [
      "Medial epicondyle of humerus and coronoid process of ulna",
      "Anterior shaft of radius; interosseous membrane",
      "Shaft of radius; interosseous membrane"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-semitendinosus",
    "question": "What is the insertion of the Semitendinosus?",
    "group": "Thigh",
    "answer": "Proximal, medial surface of tibia",
    "options": [
      "Pectineal line",
      "Lesser trochanter",
      "Tibial tuberosity"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_carpi_radialis_brevis",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorCarpiRadialisBrevis-5.png",
    "answer": "Extensor Carpi Radialis Brevis"
  },
  {
    "id": "insertion-Thigh-thigh-vastus_medialis",
    "question": "What is the insertion of the Vastus Medialis?",
    "group": "Thigh",
    "answer": "Tibial tuberosity",
    "options": [
      "Lesser trochanter",
      "Head of fibula",
      "Linea aspera"
    ]
  },
  {
    "id": "insertion-Arm-arm-coracobrachialis",
    "question": "What is the insertion of the Coracobrachialis?",
    "group": "Arm",
    "answer": "Lateral aspect of styloid process of radius",
    "options": [
      "Radial tuberosity",
      "Ulnar tuberosity and coronoid process",
      "Olecranon of ulna"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-rhomboid_major",
    "question": "What is the origin of the Rhomboid Major?",
    "group": "Shoulder",
    "answer": "Ligamentum nuchae and the spinous processes of T2-T5",
    "options": [
      "Transverse process of C1-C4",
      "Occipital bone| ligamentum nuchae| and spinous processes of C7-12",
      "3rd to 5th ribs near their costal cartilages"
    ]
  },
  {
    "id": "origin-Leg-leg-extensor_hallucis_longus",
    "question": "What is the origin of the Extensor Hallucis Longus?",
    "group": "Leg",
    "answer": "Anterior surface of fibula",
    "options": [
      "Medial surface of the fibula",
      "Mid Lateral margin of fibula",
      "Soleal line"
    ]
  },
  {
    "id": "insertion-Leg-leg-soleus",
    "question": "What is the insertion of the Soleus?",
    "group": "Leg",
    "answer": "Calcaneus via calcaneal tendon",
    "options": [
      "Navicular,  all three cuneiforms, cuboid, second, third, and fourth metatarsal bones",
      "Superior surface of phalanges, distal phalanx of great toe",
      "Dorsal surface of base of metatarsal bone 5"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_carpi_radialis_longus",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorCarpiRadialisLongus-5.png",
    "answer": "Extensor Carpi Radialis Longus"
  },
  {
    "id": "action-Axial-axial-splenius_cervicis",
    "question": "What is the action of the Splenius Cervicis?",
    "group": "Axial",
    "answer": "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation",
    "options": [
      "Lateral flexion of vertebrae",
      "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
      "Expands thoracic cavity"
    ]
  },
  {
    "id": "identify-Hip-hip-inferior_gemellus",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-GemellusInferior-5.png",
    "answer": "Inferior Gemellus"
  },
  {
    "id": "action-Axial-axial-iliocostalis_cervicis",
    "question": "What is the action of the Iliocostalis Cervicis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion",
    "options": [
      "Rotation of vertebrae",
      "Bilateral: extension",
      "Bilateral: extends the neck; Unilateral: lateral flexion, ipsilateral rotation"
    ]
  },
  {
    "id": "insertion-Axial-axial-diaphragm",
    "question": "What is the insertion of the Diaphragm?",
    "group": "Axial",
    "answer": "Central tendon sheet",
    "options": [
      "",
      "Inferior border of superior rib",
      "12th rib, transverse processes of L1-L5"
    ]
  },
  {
    "id": "insertion-Leg-leg-fibularis_tertius",
    "question": "What is the insertion of the Fibularis Tertius?",
    "group": "Leg",
    "answer": "Dorsal surface of base of metatarsal bone 5",
    "options": [
      "Calcaneus via calcaneal tendon",
      "Posterior portion of calcaneus",
      "Inferior surface of distal phalanges, toes 2-5"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-adductor_longus",
    "question": "What is the insertion of the Adductor Longus?",
    "group": "Thigh",
    "answer": "Linea aspera",
    "options": [
      "Lateral condyle of tibia",
      "Head of fibula",
      "Proximal, medial surface of tibia"
    ]
  },
  {
    "id": "identify-Leg-leg-fibularis_tertius",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-FibularisTertius-13.png",
    "answer": "Fibularis Tertius"
  },
  {
    "id": "identify-Thigh-thigh-adductor_longus",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-AdductorLongus-13.png",
    "answer": "Adductor Longus"
  },
  {
    "id": "origin-Forearm-forearm-flexor_digitorum_profundus",
    "question": "What is the origin of the Flexor Digitorum Profundus?",
    "group": "Forearm",
    "answer": "Medial and posterior surfaces of ulnar| medial surfaces of coronoid process; interosseous membrane",
    "options": [
      "Anterior and medial surfaces of distal ulna",
      "Shaft of radius; interosseous membrane",
      "Proximal dorsal surfaces of ulna and radius"
    ]
  },
  {
    "id": "action-Axial-axial-scalenes",
    "question": "What is the action of the Scalenes?",
    "group": "Axial",
    "answer": "Elevate ribs; Bilateral: flexes the neck; Unilateral: Lateral flexion & ipsilateral rotation",
    "options": [
      "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation",
      "Lateral flexion of vertebrae",
      "Bilateral: extension"
    ]
  },
  {
    "id": "action-Axial-axial-longissimus_thoracis",
    "question": "What is the action of the Longissimus Thoracis?",
    "group": "Axial",
    "answer": "Bilateral: extension; Unilateral: lateral flexion",
    "options": [
      "Rotation of vertebrae",
      "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
      "Bilateral: extends; Unilateral: contralateral rotation"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-extensor_carpi_radialis_brevis",
    "question": "What is the insertion of the Extensor Carpi Radialis Brevis?",
    "group": "Forearm",
    "answer": "Base of third metacarpal bone",
    "options": [
      "Base of proximal phalanx of thumb",
      "Posterior surface of proximal phalanx of little finger",
      "Pisiform, hamate, and base of fifth metacarpal bones"
    ]
  },
  {
    "id": "identify-Thigh-thigh-semitendinosus",
    "group": "Thigh",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Thigh-Semitendinosus-5.png",
    "answer": "Semitendinosus"
  },
  {
    "id": "insertion-Hip-hip-quadratus_femoris",
    "question": "What is the insertion of the Quadratus Femoris?",
    "group": "Hip",
    "answer": "Intertrochanteric crest of femur",
    "options": [
      "Iliotibial tract, gluteal tuberosity",
      "Greater trochanter of femur",
      "Iliotibial tract"
    ]
  },
  {
    "id": "insertion-Axial-axial-multifidus",
    "question": "What is the insertion of the Multifidus?",
    "group": "Axial",
    "answer": "Spinous processes of 3rd superior vertebrae",
    "options": [
      "12th rib, transverse processes of L1-L5",
      "Inferior borders of ribs 8-12, linea alba",
      ""
    ]
  },
  {
    "id": "identify-Leg-leg-fibularis_longus",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-FibularisLongus-9.png",
    "answer": "Fibularis Longus"
  },
  {
    "id": "action-Thigh-thigh-vastus_lateralis",
    "question": "What is the action of the Vastus Lateralis?",
    "group": "Thigh",
    "answer": "Extension at knee",
    "options": [
      "Lesser trochanter",
      "Adducts and flexes the thigh, and helps to laterally rotate the hip joint",
      "Flexion at knee"
    ]
  },
  {
    "id": "origin-Leg-leg-flexor_hallucis_longus",
    "question": "What is the origin of the Flexor Hallucis Longus?",
    "group": "Leg",
    "answer": "Posterior surface of fibula",
    "options": [
      "Medial surface of the fibula",
      "Head and proximal shaft of fibula",
      "Anterior surface of fibula"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-flexor_carpi_ulnaris",
    "question": "What is the insertion of the Flexor Carpi Ulnaris?",
    "group": "Forearm",
    "answer": "Pisiform, hamate, and base of fifth metacarpal bones",
    "options": [
      "Lateral margin of olecranon and ulnar shaft",
      "Posterior surface of proximal phalanx of little finger",
      "Base of distal phalanx of thumb"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-flexor_carpi_radialis",
    "question": "What is the insertion of the Flexor Carpi Radialis?",
    "group": "Forearm",
    "answer": "Bases of second and third metacarpal bones",
    "options": [
      "Posterior surface of proximal phalanx of index finger",
      "Anterolateral surface of radius distal to the radial tuberosity",
      "Base of distal phalanges of digits 2-5"
    ]
  },
  {
    "id": "action-Forearm-forearm-flexor_digitorum_profundus",
    "question": "What is the action of the Flexor Digitorum Profundus?",
    "group": "Forearm",
    "answer": "Flexion at distal interphalangeal joints",
    "options": [
      "Supinates forearm and hand",
      "Extension at finger joints",
      "Extension at joints of thumb, abduction at wrist"
    ]
  },
  {
    "id": "action-Axial-axial-longus_colli",
    "question": "What is the action of the Longus Colli?",
    "group": "Axial",
    "answer": "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation",
    "options": [
      "Expands thoracic cavity",
      "Flexes, compresses abdominal cavity",
      "Bilateral: flexes the neck; Unilateral: lateral flexion & contralateral rotation"
    ]
  },
  {
    "id": "insertion-Axial-axial-quadratus_lumborum",
    "question": "What is the insertion of the Quadratus Lumborum?",
    "group": "Axial",
    "answer": "12th rib, transverse processes of L1-L5",
    "options": [
      "Mastoid process",
      "Ribs 1 & 2",
      ""
    ]
  },
  {
    "id": "identify-Leg-leg-flexor_hallucis_longus",
    "group": "Leg",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Leg-FlexorHallucisLongus-5.png",
    "answer": "Flexor Hallucis Longus"
  },
  {
    "id": "origin-Shoulder-shoulder-trapezius",
    "question": "What is the origin of the Trapezius?",
    "group": "Shoulder",
    "answer": "Occipital bone| ligamentum nuchae| and spinous processes of C7-12",
    "options": [
      "Transverse process of C1-C4",
      "Supraspinous fossa of scapula",
      "Spinous processes of inferior thoracic and all lumbar and sacral vertebrae| ribs 8-12| and thoracolumbar fascia"
    ]
  },
  {
    "id": "origin-Leg-leg-plantaris",
    "question": "What is the origin of the Plantaris?",
    "group": "Leg",
    "answer": "Lateral supracondylar ridge",
    "options": [
      "Anterior surface of fibula",
      "Interosseous membrane and adjacent shafts of tibia and fibula",
      "Soleal line"
    ]
  },
  {
    "id": "insertion-Axial-axial-sternocleidomastoid",
    "question": "What is the insertion of the Sternocleidomastoid?",
    "group": "Axial",
    "answer": "Mastoid process",
    "options": [
      "Ribs 1 & 2",
      "Spinous processes of 3rd superior vertebrae",
      "Transverse process"
    ]
  },
  {
    "id": "action-Forearm-forearm-flexor_carpi_ulnaris",
    "question": "What is the action of the Flexor Carpi Ulnaris?",
    "group": "Forearm",
    "answer": "Flexion at wrist",
    "options": [
      "Extension at joints of thumb, abduction at wrist",
      "Flexion and abduction at wrist",
      "Extension at finger joints"
    ]
  },
  {
    "id": "origin-Leg-leg-fibularis_brevis",
    "question": "What is the origin of the Fibularis Brevis?",
    "group": "Leg",
    "answer": "Mid Lateral margin of fibula",
    "options": [
      "Soleal line",
      "Medial surface of the fibula",
      "Lateral condyle and proximal shaft of tibia"
    ]
  },
  {
    "id": "identify-Hip-hip-quadratus_femoris",
    "group": "Hip",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Hip-QuadratusFemoris-5.png",
    "answer": "Quadratus Femoris"
  },
  {
    "id": "insertion-Forearm-forearm-palmaris_longus",
    "question": "What is the insertion of the Palmaris Longus?",
    "group": "Forearm",
    "answer": "Palmar aponeurosis and flexor retinaculum",
    "options": [
      "Lateral margin of first metacarpal bone and trapezium",
      "Anterolateral surface of radius distal to the radial tuberosity",
      "Base of proximal phalanx of thumb"
    ]
  },
  {
    "id": "origin-Shoulder-shoulder-pectoralis_minor",
    "question": "What is the origin of the Pectoralis Minor?",
    "group": "Shoulder",
    "answer": "3rd to 5th ribs near their costal cartilages",
    "options": [
      "First rib",
      "Lateral border of scapula",
      "Ligamentum nuchae and the spinous processes of T2-T5"
    ]
  },
  {
    "id": "origin-Hip-hip-gluteus_medius",
    "question": "What is the origin of the Gluteus Medius?",
    "group": "Hip",
    "answer": "Anterior iliac crest",
    "options": [
      "Anterior obturator foramen",
      "Lateral surface of ilium between inferior and anterior gluteal lines",
      "Iliac crest| posterior gluteal line| lateral surface of ilium"
    ]
  },
  {
    "id": "action-Arm-arm-long_head_of_biceps_brachii",
    "question": "What is the action of the Long Head of Biceps Brachii?",
    "group": "Arm",
    "answer": "Flexion at elbow and shoulder, supination of forearm and hand",
    "options": [
      "Extension at elbow",
      "Flexion at elbow",
      "Extension at elbow, extension and adduction at shoulder"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_pollicis_brevis",
    "question": "What is the action of the Extensor Pollicis Brevis?",
    "group": "Forearm",
    "answer": "Extension at joints of thumb, abduction at wrist",
    "options": [
      "Flexion and abduction at wrist",
      "Extension at joints of little finger, extension at wrist",
      "Flexion at wrist"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-anconeus",
    "question": "What is the insertion of the Anconeus?",
    "group": "Forearm",
    "answer": "Lateral margin of olecranon and ulnar shaft",
    "options": [
      "Base of middle phalanges of digits 2-5",
      "Lateral surface of radius distal to the radial tuberosity",
      "Posterior surfaces of phalanges of digits 2-5"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_carpi_ulnaris",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorCarpiUlnaris-5.png",
    "answer": "Extensor Carpi Ulnaris"
  },
  {
    "id": "action-Leg-leg-fibularis_tertius",
    "question": "What is the action of the Fibularis Tertius?",
    "group": "Leg",
    "answer": "Dorsiflexion at ankle, eversion of foot",
    "options": [
      "Extension at joints of great toe, dorsiflexion at ankle",
      "Plantar flexion at ankle",
      "Plantar flexion at ankle, flexion at knee"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_pollicis_longus",
    "question": "What is the action of the Extensor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Extension at joints of thumb, abduction at wrist",
    "options": [
      "Pronates forearm and hand",
      "Flexion and abduction at wrist",
      "Extension and abduction at wrist"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-pronator_quadratus",
    "question": "What is the insertion of the Pronator Quadratus?",
    "group": "Forearm",
    "answer": "Anterolateral surface of distal portion of radius",
    "options": [
      "Base of proximal phalanx of thumb",
      "Posterior surface of proximal phalanx of index finger",
      "Palmar aponeurosis and flexor retinaculum"
    ]
  },
  {
    "id": "action-Leg-leg-extensor_digitorum_longus",
    "question": "What is the action of the Extensor Digitorum Longus?",
    "group": "Leg",
    "answer": "Extension of toes 2-5, dorsiflexion at ankle",
    "options": [
      "Flexion at joints of great toe, plantar flexion",
      "Flexion at joints of toes 2-5, plantarflexion",
      "Plantar flexion at ankle"
    ]
  },
  {
    "id": "insertion-Thigh-thigh-adductor_magnus",
    "question": "What is the insertion of the Adductor Magnus?",
    "group": "Thigh",
    "answer": "Linea aspera and medial tubercle of femur",
    "options": [
      "Medial surface of tibial shaft",
      "Head of fibula",
      "Linea aspera"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-supinator",
    "question": "What is the insertion of the Supinator?",
    "group": "Forearm",
    "answer": "Anterolateral surface of radius distal to the radial tuberosity",
    "options": [
      "Lateral margin of first metacarpal bone and trapezium",
      "Bases of second and third metacarpal bones",
      "Palmar aponeurosis and flexor retinaculum"
    ]
  },
  {
    "id": "insertion-Forearm-forearm-extensor_pollicis_brevis",
    "question": "What is the insertion of the Extensor Pollicis Brevis?",
    "group": "Forearm",
    "answer": "Base of proximal phalanx of thumb",
    "options": [
      "Anterolateral surface of distal portion of radius",
      "Bases of second and third metacarpal bones",
      "Lateral margin of first metacarpal bone and trapezium"
    ]
  },
  {
    "id": "action-Forearm-forearm-abductor_pollicis_longus",
    "question": "What is the action of the Abductor Pollicis Longus?",
    "group": "Forearm",
    "answer": "Adduction at joints of thumb and wrist",
    "options": [
      "Flexion and abduction at wrist",
      "Pronates forearm and hand",
      "Flexion at joints at thumb"
    ]
  },
  {
    "id": "action-Forearm-forearm-extensor_carpi_radialis_brevis",
    "question": "What is the action of the Extensor Carpi Radialis Brevis?",
    "group": "Forearm",
    "answer": "Extension and abduction at wrist",
    "options": [
      "Extension at joints of thumb, abduction at wrist",
      "Extension and adduction at joints of index finger",
      "Pronates forearm and hand"
    ]
  },
  {
    "id": "origin-Leg-leg-flexor_digitorum_longus",
    "question": "What is the origin of the Flexor Digitorum Longus?",
    "group": "Leg",
    "answer": "Posteromedial surface of tibia",
    "options": [
      "Medial condyle of femur",
      "Lateral supracondylar ridge",
      "Posterior surface of fibula"
    ]
  },
  {
    "id": "action-Axial-axial-longus_capitis",
    "question": "What is the action of the Longus Capitis?",
    "group": "Axial",
    "answer": "Bilateral: flex vertebral column; Unilateral: ipsilateral rotation",
    "options": [
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: ipsilateral rotation",
      "Bilateral: extension; Unilateral: lateral flexion, contralateral rotation",
      "Bilateral: extension"
    ]
  },
  {
    "id": "action-Forearm-forearm-supinator",
    "question": "What is the action of the Supinator?",
    "group": "Forearm",
    "answer": "Supinates forearm and hand",
    "options": [
      "Flexion and abduction at wrist",
      "Flexion at proximal interphalangeal, metacarpophalangeal, and wrist joints",
      "Flexion at wrist"
    ]
  },
  {
    "id": "action-Axial-axial-rectus_abdominis",
    "question": "What is the action of the Rectus Abdominis?",
    "group": "Axial",
    "answer": "Flexes, compresses abdominal cavity",
    "options": [
      "Lateral flexion of vertebrae",
      "Bilateral: flexes, compresses, depresses ribs; Unilateral: contralateral rotation",
      "Rotation of vertebrae"
    ]
  },
  {
    "id": "origin-Forearm-forearm-extensor_carpi_ulnaris",
    "question": "What is the origin of the Extensor Carpi Ulnaris?",
    "group": "Forearm",
    "answer": "Lateral epicondyle of humerus",
    "options": [
      "Shaft of radius; interosseous membrane",
      "Medial epicondyle of humerus",
      "Medial and posterior surfaces of ulnar| medial surfaces of coronoid process; interosseous membrane"
    ]
  },
  {
    "id": "action-Leg-leg-extensor_hallucis_longus",
    "question": "What is the action of the Extensor Hallucis Longus?",
    "group": "Leg",
    "answer": "Extension at joints of great toe, dorsiflexion at ankle",
    "options": [
      "Plantar flexion at ankle",
      "Dorsiflexion at ankle, inversion of foot",
      "Flexion at joints of toes 2-5, plantarflexion"
    ]
  },
  {
    "id": "action-Foot-foot-abductor_hallucis",
    "question": "What is the action of the Abductor Hallucis?",
    "group": "Foot",
    "answer": "Abduction at metatarsophalangeal joints of great toe",
    "options": [
      "Flexion of proximal interphalangeal joints of toes 2-5",
      "Flexion at joints of toes 2-5",
      "Adduction of metatarsophalangeal joints of toes 3-5; flexion of metatarsophalangeal joints and extension at interphalangeal joints"
    ]
  },
  {
    "id": "identify-Forearm-forearm-extensor_indicis",
    "group": "Forearm",
    "question": "What muscle is this?",
    "image": "https://www.muscle-atlas.org/assets/images/muscles/png/Forearm-ExtensorIndicis-5.png",
    "answer": "Extensor Indicis"
  },
  {
    "id": "action-Foot-foot-extensor_digitorum_brevis",
    "question": "What is the action of the Extensor Digitorum Brevis?",
    "group": "Foot",
    "answer": "Extension at metatarsophalangeal joints of toes 1-4",
    "options": [
      "Flexion at metatarsophalangeal joint of great toe",
      "Flexion of proximal interphalangeal joints of toes 2-5",
      "Adduction and flexion at metatarsophalangeal joint of great toe"
    ]
  }
]