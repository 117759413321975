import React from "react"
import Flashcard from "./Flashcard";
import FlashcardImage from "./FlashcardImage";

export default function FlashcardList({ flashcards }){
  return(
    <div className={"card-grid"}>
      {flashcards.map((flashcard, i) => {
        if(flashcard.image && !flashcard.options){
          return <FlashcardImage flashcard={flashcard} question={i + 1} key={flashcard.id}/>
          // return <Flashcard flashcard={flashcard} question={i + 1} key={flashcard.id}/>
        } else {
          return <Flashcard flashcard={flashcard} question={i + 1} key={flashcard.id}/>
        }
      })}
    </div>
  )
}