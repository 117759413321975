import { Analytics } from "@vercel/analytics/react";
import React, { useEffect, useRef, useState } from "react";
import { defaultQuestions } from "./defaultQuestions";
import FlashcardList from "./FlashcardList";
import ParticleBackground from "./ParticleBackground";

function App() {
  const [flashcards, setFlashcards] = useState(defaultQuestions);
  const [categories, setCategories] = useState(["All", "Upper Extremity", "Shoulder", "Arm", "Forearm", "Hand", "Lower Extremity", "Thigh", "Hip", "Leg", "Foot", "Axial"]);
  const [data, setData] = useState(new Map());
  const [questions, setQuestions] = useState(defaultQuestions);

  const categoryEl = useRef();
  const amountEl = useRef();

  useEffect(() => {

    let storage = new Map();
    let groups = ["All"];
    fetch("./Muscle Spreadsheet - Sheet1.csv").then((r) => {
      r.text().then(r => {
        let data = r.split("\n");
        data.shift();
        for (let i = 0; i < data.length; i++) {
          let line = data[i];
          let [name, group, origin, insertion, action, imageName, pov] = line.split(",");
          if (!name) continue;
          storage.set(`${group}-${name}`, {
            name,
            group,
            origin: origin.replaceAll("|", ","),
            insertion: insertion.replaceAll("|", ","),
            action: action.replaceAll("|", ","),
            imageName,
            pov
          });
          if (!groups.includes(group)) {
            groups.push(group);
          }
        }
      });
    });

    setData(storage);
  }, []);

  function createQuestions() {
    let allQuestions = [];
    for (let category of categories) {
      let origins = [];
      let insertions = [];
      let actions = [];
      // create possible answers
      for (let [, { group, origin, insertion, action }] of data) {
        if (category === group) {
          if (!origins.includes(origin)) origins.push(origin);
          if (!insertions.includes(insertion)) insertions.push(insertion);
          if (!actions.includes(action)) actions.push(action);
        }
      }
      // create questions
      for (let [name, { name: img, group, origin, insertion, action, imageName, pov }] of data) {
        if (category === group) {
          // check for origin and insertion, all muscles guaranteed action
          if (origins && insertion) {
            allQuestions.push({
              id: `origin-${group}-${name.toLowerCase().replaceAll(" ", "_")}`,
              question: `What is the origin of the ${img}?`,
              group,
              answer: origin,
              options: origins.filter((a) => a !== origin).sort(() => Math.random() - 0.5).slice(0, 3).filter((a) => a !== action).sort(() => Math.random() - 0.5).slice(0, 3)
            });
            allQuestions.push({
              id: `insertion-${group}-${name.toLowerCase().replaceAll(" ", "_")}`,
              question: `What is the insertion of the ${img}?`,
              group,
              answer: insertion,
              options: insertions.filter((a) => a !== insertion).sort(() => Math.random() - 0.5).slice(0, 3)
            });
          }

          allQuestions.push({
            id: `action-${group}-${name.toLowerCase().replaceAll(" ", "_")}`,
            question: `What is the action of the ${img}?`,
            group,
            answer: action,
            options: actions.filter((a) => a !== action).sort(() => Math.random() - 0.5).slice(0, 3)
          });

          if (!isNaN(parseInt(pov))) {
            allQuestions.push({
              id: `identify-${group}-${name.toLowerCase().replaceAll(" ", "_")}`,
              group,
              question: "What muscle is this?",
              image: `https://www.muscle-atlas.org/assets/images/muscles/png/${group}-${imageName ? imageName : img.replaceAll(" ", "")}-${pov}.png`,
              answer: img
            });
          }

        }
      }
    }
    setQuestions(allQuestions);
    setFlashcards(allQuestions);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let filtered = [];
    createQuestions();
    // console.log(questions);
    if (categoryEl.current.value === "All") {
      setFlashcards(questions.sort(() => Math.random() - 0.5).slice(0, amountEl.current.value));
      return
    } else if (categoryEl.current.value === "Upper Extremity") {
      for (let question of questions) {
        if (["Shoulder", "Arm", "Hand"].includes(question.group)) {
          filtered.push(question);
        }
      }
    } else if (categoryEl.current.value === "Lower Extremity") {
      for (let question of questions) {
        if (["Thigh", "Hip", "Leg", "Foot"].includes(question.group)) {
          filtered.push(question);
        }
      }
    } else {
      for (let question of questions) {
        if (question.group === categoryEl.current.value) {
          filtered.push(question);
        }
      }
    }
    filtered = filtered.sort(() => Math.random() - 0.5).slice(0, amountEl.current.value);
    setFlashcards(filtered);
  }

  return (<div>
    <ParticleBackground/>
    <Analytics/>
    <div className={"header"}>
      <form onSubmit={handleSubmit}>
        <div className={"form-group"}>
          <label htmlFor={"category"}>Muscle Group</label>
          <select id={"category"} ref={categoryEl}>
            {categories.map((category) => {
              return <option value={category} key={category}>{category}</option>;
            })}
          </select>
        </div>
      </form>
      <form onSubmit={handleSubmit}>
        <div className={"form-group"}>
          <label htmlFor={"amount"}>Amount of Questions</label>
          <input type={"number"} id={"amount"} min={1} max={391} defaultValue={10} ref={amountEl}/>
        </div>
      </form>
      <form onSubmit={handleSubmit}>
        <button className={"btn"}>Generate</button>
      </form>
    </div>
    <div className={"container"}>
      <FlashcardList flashcards={flashcards}/>
    </div>
  </div>);
}

export default App;