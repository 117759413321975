import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

export default function ParticleBackground() {
  const particlesInit = useCallback(async engine => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
  }, []);
  return (<Particles
    id="tsparticles"
    init={particlesInit}
    loaded={particlesLoaded}
    options={{
      background: {
        color: {
          value: "#2cc181"
        }
      }, fpsLimit: 120, interactivity: {
        events: {
          onHover: {
            enable: true, mode: "attract"
          }, resize: false
        }, modes: {
          push: {
            quantity: 4
          }, attract: {
            distance: 200, duration: 0.4
          }
        }
      }, particles: {
        color: {
          value: "#f5f5f5"
        }, links: {
          color: "#f5f5f5", distance: 150, enable: true, opacity: 0.5, width: 1
        }, move: {
          direction: "none", enable: true, outModes: {
            bounce: {
              distance: 100
            }
          }, random: false, speed: 3, straight: false
        }, number: {
          density: {
            enable: true, area: 800
          }, value: 80
        }, opacity: {
          value: 0.5
        }, shape: {
          type: "circle"
        }, size: {
          value: { min: 1, max: 5 }
        }
      }, detectRetina: true
    }}
  />);
}
